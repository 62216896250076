import {
  useTheme,
  MenuItem,
  ListItemIcon,
  Typography,
  Menu,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
const MenuView = ({
  anchorEl,
  open,
  handleClose,
  onClickItem,
  options,
  item,
  isContext,
  hideValue = [],
  disabledValue = [],
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const contextProps = {
    anchorReference: "anchorPosition",
    anchorPosition: anchorEl
      ? { top: anchorEl?.mouseY, left: anchorEl?.mouseX }
      : undefined,
  };

  return (
    <Menu
      id="long-menu"
      key="long-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      {...(isContext ? contextProps : {})}
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      sx={{
        zIndex: 1303,
      }}
      slotProps={{
        paper: {
          sx: {
            // maxHeight: 48 * 4.5,
            mr: "4rem",
            minWidth: "20ch",
            borderRadius: theme.borderRadius.main,
          },
        },
      }}
    >
      {options?.map((option) => {
        if (hideValue?.includes(option?.value)) return null;
        return (
          <Tooltip
            key={option?.value}
            arrow
            placement={"left"}
            title={option?.tooltip ? t(option?.tooltip) : ""}
          >
            <span>
              <MenuItem
                disabled={disabledValue?.includes(option?.value)}
                onClick={(e) => onClickItem(e, option?.value, item)}
              >
                <ListItemIcon sx={{ color: "color.slate.600" }}>
                  {option?.icon}
                </ListItemIcon>
                <Typography variant="inherit" color="color.slate.600" noWrap>
                  {t(option?.label)}
                </Typography>
              </MenuItem>
            </span>
          </Tooltip>
        );
      })}
    </Menu>
  );
};

export default MenuView;
