import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";

import IntegrationsDialogView from "../../../components/Header/DatasetHeader/IntegrationsDialogView";
import { setDataSource } from "../../../store/slices/global";
import EndPoints from "../../../APICall/EndPoints";
import AccountView from "./AccountView";
import DsCountView from "./DsCountView";
import HeaderView from "../HeaderView";
import APICall from "../../../APICall";

const BankAccount = ({ type = "all", tab }) => {
  const navigate = useNavigate();
  const isDSChanged = useRef(false);
  const dispatch = useDispatch();

  //redux
  const dataSource = useSelector((state) => state.globalSlice.dataSource);

  //state
  const [accountingTab, setAccountingTab] = useState("all");
  const [accountingValue, setAccountingValue] = useState({
    connected: [],
    disconnected: [],
    archived: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  //data
  const typeList = useMemo(() => {
    return dataSource?.map((o1) => o1.type);
  }, [dataSource]);

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
      }
    });
  };

  //functions
  const onClickEdit = () => {
    navigate("/settings/Integrations");
  };

  const handleClickClose = () => {
    setIsOpen(false);
    if (isDSChanged.current) {
      getDataSource();
    }
  };
  const onClickAddBank = () => {
    setIsOpen("add");
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "30rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IntegrationsDialogView
        open={Boolean(isOpen)}
        type={isOpen}
        handleClickClose={handleClickClose}
        isDSChanged={isDSChanged}
      />

      <AccountView
        dsType={[10, 12, 1]}
        type={type}
        tab={tab}
        onClickAddBank={onClickAddBank}
        sx={{ mt: "1.5rem" }}
        // showTotal
      />

      <HeaderView
        title="Integrations"
        sx={{ mt: "4rem" }}
        onClickEdit={onClickEdit}
        component={
          <DsCountView
            value={accountingValue}
            setValue={setAccountingValue}
            setTab={setAccountingTab}
            tab={accountingTab}
            dsType={[19]}
          />
        }
      />
      <AccountView
        dsType={[19]}
        type={type}
        tab={accountingTab}
        onClickAddBank={onClickAddBank}
        sx={{ mt: "1.5rem" }}
      />

      {typeList?.includes(2) ? (
        <>
          <HeaderView
            title="Misc"
            sx={{ mt: "4rem" }}
            onClickEdit={onClickEdit}
          />
          <AccountView
            dsType={[2]}
            type={type}
            sx={{ mt: "1.5rem" }}
            onClickAddBank={onClickAddBank}
          />
        </>
      ) : null}
    </Box>
  );
};

export default BankAccount;
