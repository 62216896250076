import { Divider, Paper, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";

import {
  formatDateToLocal,
  getTailwindColor,
  formatAmount,
} from "../../Helper/data";
import theme from "../../theme";

const CustomTooltip = ({
  active,
  payload,
  barsData,
  dynamicColor,
  showOriginalColor,
}) => {
  if (active && payload && payload.length) {
    const payloadData = payload?.[0]?.payload;
    return (
      <Paper
        elevation={3}
        sx={{
          p: "1rem",
          borderRadius: theme.borderRadius.main,
          backgroundColor: theme.palette.color.white,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "0.8rem",
            fontWeight: 700,
            width: "100%",
            mb: "0.5rem",
          }}
        >
          {" "}
          {payloadData?.due_date
            ? formatDateToLocal(payloadData?.due_date, "MMM yyyy")
            : ""}
        </Typography>

        {barsData
          ?.sort((a, b) => a?.position - b?.position)
          ?.map((item) => {
            const value = item?.absoluteValue
              ? Math.abs(payloadData?.[item?.dataKey] || 0)
              : payloadData?.[item?.dataKey] || item?.value || 0;
            if (item?.hideTooltip || (!item?.hideBar && value === 0)) {
              return null;
            }
            return (
              <Stack key={item?.key} sx={{ minWidth: "10rem", width: "100%" }}>
                {item?.showTopSeparator ? (
                  <Divider sx={{ width: "100%", my: "0.5rem" }} />
                ) : null}
                <Stack
                  direction={"row"}
                  sx={{ width: "100%" }}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {item?.icon || (
                      <span
                        style={{
                          width: "0.6rem",
                          height: "0.6rem",
                          borderRadius: 6,
                          backgroundColor: dynamicColor
                            ? payloadData?.[item?.dataKey] >= 0
                              ? getTailwindColor("green", item?.shade || 500)
                              : getTailwindColor("red", item?.shade || 500)
                            : showOriginalColor
                              ? item?.color
                              : getTailwindColor(
                                  item?.color,
                                  item?.shade || 500
                                ),
                          marginRight: "0.5rem",
                        }}
                      ></span>
                    )}
                    {item?.hideTitle ? null : (
                      <Typography
                        sx={{
                          fontSize: "0.7rem",
                          lineHeight: "0.7rem",
                          fontWeight: item?.isBold ? 800 : 500,
                          display: "flow-root",
                          textAlign: "left",
                          width: "9rem",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t(item?.key)}
                      </Typography>
                    )}
                  </div>

                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      fontWeight: item?.isBold ? 800 : 600,
                      ml: "0.5rem",
                      minWidth: "7rem",
                      textAlign: "right",
                    }}
                  >
                    {formatAmount({
                      amount: value,
                    })}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
      </Paper>
    );
  }

  return null;
};
export default CustomTooltip;
