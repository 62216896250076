import { Box, Typography, useTheme } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";

import { Color, Fonts } from "../../Helper";

const HeaderView = ({ title, onClickEdit, sx, isManage, component }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          color: Color.black,
          fontSize: "1.15rem",
        }}
      >
        {t(title)}
      </Typography>
      <span style={{ display: "flex", alignItems: "center" }}>
        {component}

        {onClickEdit ? (
          <Box
            onClick={onClickEdit}
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: theme.palette.color.slate[200],
              borderRadius: theme.borderRadius.main,
              cursor: "pointer",
              zIndex: 10,
              px: "0.25rem",
              pr: "0.5rem",
              py: "0.25rem",
              "&:hover": {
                backgroundColor: theme.palette.color.slate[300],
              },
            }}
          >
            <SettingsIcon
              sx={{ color: Color.black, fontSize: "1.1rem", m: "0.2rem" }}
            />
            <Typography
              sx={{
                fontWeight: 500,
                color: Color.black,
                fontSize: "0.8rem",
                fontFamily: Fonts.Text,
              }}
            >
              {t("Manage")}
            </Typography>
          </Box>
        ) : null}
      </span>
    </Box>
  );
};
export default HeaderView;
