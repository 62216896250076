import { Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";

import { Color, Constant, Fonts } from "../../Helper";
import { isPlanExpired } from "../../Helper/data";

const Banner = ({ setShowBanner, showBanner }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const timer = useRef(null);

  //redux
  const refreshBalance = useSelector((state) => state.appSlice?.refreshBalance); //keep this for rerender
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isPageNotFound = useSelector(
    (state) => state.commonSlice.isPageNotFound
  );

  let day = moment(subscription?.expiry_date ?? new Date()).diff(
    moment(),
    "days"
  );
  const isMaintenance = t("maintenance_banner_message") !== "hide";
  const MaintenanceTimer = t("maintenance_timestamp");

  // State
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (
      (isMaintenance ||
        (subscription?.name === "Trial" && isAllHeaderApiFetched)) &&
      !isPageNotFound
    ) {
      Constant.BANNER_HEIGHT = "1.5rem";
      setShowBanner(true);
    } else {
      Constant.BANNER_HEIGHT = "0rem";
      setShowBanner(false);
    }
  }, [
    subscription?.name,
    isAllHeaderApiFetched,
    isPageNotFound,
    t,
    refreshBalance,
    setShowBanner,
    isMaintenance,
  ]);

  useEffect(() => {
    if (isMaintenance && MaintenanceTimer) {
      clearInterval(timer.current);
      setTimeLeft(MaintenanceTimer);

      // timer.current = setInterval(() => {
      //   setTimeLeft((prev) => {
      //     if (prev > 0) {
      //       return prev - 1;
      //     } else {
      //       clearInterval(timer.current);
      //       return 0;
      //     }
      //   });
      // }, 1000);
    } else {
      clearInterval(timer.current);
    }

    return () => {
      // clearInterval(timer.current);
    };
  }, [MaintenanceTimer, isMaintenance, refreshBalance]);

  const formatTime = (seconds) => {
    if (seconds > 0) {
      const d = Math.floor(seconds / 86400); // 86400 seconds in a day (3600 * 24)
      const h = Math.floor((seconds % 86400) / 3600); // Remaining hours after accounting for days
      const m = Math.floor((seconds % 3600) / 60); // Remaining minutes after accounting for hours
      const s = seconds % 60; // Remaining seconds after accounting for minutes

      return `${d > 0 ? d.toString().padStart(2, "0") + "d " : ""}${h
        .toString()
        .padStart(
          2,
          "0"
        )}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
    }
    return "00:00:00";
  };

  const onClickLink = () => {
    navigate("/settings/subscription");
  };

  const getText = () => {
    let text = "";

    if (isPlanExpired(subscription)) {
      text = t("Your subscription plan  Expired - Please upgrade you plan.", {
        subscription: subscription?.name,
      });
      return text;
    }
    if (subscription?.name === "Trial") {
      if (day >= 0 && day <= 2) {
        text = t(
          "Your subscription plan will Expire, Book now for a 25% discount.",
          {
            subscription: subscription?.name,
            day: day + 1,
            day_plural: day > 0 ? t("day_s") : t("day"),
          }
        );
        return text;
      }
      text = t("You can still test finban, Book now for a 25% discount.", {
        subscription: subscription?.name,
        day: day + 1,
        day_plural: day > 0 ? t("day_s") : t("day"),
      });
    } else {
      if (day >= 0 && day <= 2) {
        text = t("Your subscription plan will Expired in day", {
          subscription: subscription?.name,
          day: day + 1,
          day_plural: day > 0 ? t("day_s") : t("day"),
        });
        return text;
      }
    }
    return text;
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Paper
      sx={{
        fontSize: "1rem",
        paddingBlock: "0.1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
        background: isMaintenance
          ? theme.palette.color.red[500]
          : theme?.palette?.primary.dark,
        borderRadius: 0,
      }}
      square
      elevation={0}
    >
      <Typography
        sx={{
          marginLeft: "0.625rem",
          background: "inherit",
          flexGrow: 1,
          color: Color?.white,
          fontFamily: Fonts?.Text,
          fontSize: { xs: "0.8rem", small: "1rem" },
          fontWeight: 500,
          cursor: "pointer",
          height: Constant.BANNER_HEIGHT,
        }}
        onClick={onClickLink}
      >
        {isMaintenance ? `${t("maintenance_banner_message")}` : getText()}
      </Typography>
    </Paper>
  );
};
export default Banner;
