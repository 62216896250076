import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef } from "react";
import { useQuery } from "@tanstack/react-query";

import {
  setIsDsUuidUpdating,
  setIsDsUuidLoading,
  setIsFirstFetchDs,
  setConsentAlert,
  setDataSource,
} from "../store/slices/global";
import {
  getExternalDataSourceConnection,
  getFinApiDataSourceConnection,
  getDataSourceErrorById,
} from "../Helper/data";
import { setAddFrom, setProfile } from "../store/slices/settings";
import { useDeferredTimeout } from "../hooks/useDeferredTimeout";
import useDebounce from "../hooks/3-useDebounce/useDebounce";
import { setRefreshData } from "../store/slices/appmain";
import EndPoints from "../APICall/EndPoints";
import { queryClient } from "../App";
import APICall from "../APICall";

const GlobalDSFetchSystem = () => {
  const dispatch = useDispatch();

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSource = useSelector((state) => state.globalSlice?.dataSource);
  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );

  //const
  const allowDsFetch = useDeferredTimeout({
    value: Boolean(
      isAllHeaderApiFetched &&
        !isAccountSelectOverlayOpen &&
        !isFirstLoginOverlayOpen
    ),
  });

  //functions
  const refetchData = () => {
    setTimeout(() => {
      let options = {
        predicate: (query) =>
          ["transactions", "List", "invoices"].includes(query.queryKey[0]) &&
          query.queryKey[1].dataset === dataSetData?.uuid,
        type: "active",
      };
      queryClient.invalidateQueries(options, { cancelRefetch: true });
      dispatch(setRefreshData(Date.now()));
    }, 500);
  };
  return (
    <>
      {allowDsFetch &&
        dataSource?.map((item) => {
          return (
            <DsFunction
              key={item?.uuid}
              ds={item}
              account={accountByDS?.[item?.uuid]?.[0] || null}
              refetchData={refetchData}
            />
          );
        })}
    </>
  );
};

export default GlobalDSFetchSystem;

const DsFunction = ({ ds, account, refetchData }) => {
  const dispatch = useDispatch();

  //lifecycle
  const updateProfile = async (obj, dataset) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        dispatch(setProfile(data));
      }
    });
  };

  const getDataSourceById = async (id) => {
    let data = null;

    await APICall("get", EndPoints.integrations + `${id}/`).then((response) => {
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    });
    return data;
  };

  return (
    <>
      {ds?.uuid && ds.type === 12 ? (
        <FinAPiDs
          ds={ds}
          account={account}
          getDataSourceById={getDataSourceById}
          updateProfile={updateProfile}
          refetchData={refetchData}
        />
      ) : null}
      {ds?.uuid && ds.type === 19 ? (
        <IntegrationsDs
          ds={ds}
          account={account}
          getDataSourceById={getDataSourceById}
          updateProfile={updateProfile}
          refetchData={refetchData}
        />
      ) : null}
    </>
  );
};

const FinAPiDs = ({
  ds,
  account,
  getDataSourceById,
  updateProfile,
  refetchData,
}) => {
  let subscribe = useRef(null);
  const dispatch = useDispatch();
  const addFrom = useSelector((state) => state.settingsSlice.addFrom);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const FinApiQueryKey = [
    "integrations",
    {
      data_source: ds?.uuid,
      apiType: "data_source_finapi_connections",
    },
  ];

  //api
  const data_source_by_uuid_finapi_connections = useQuery({
    queryKey: FinApiQueryKey,
    queryFn: async ({ signal }) => {
      const result = await getFinApiDataSourceConnection(ds?.uuid);
      if (result) {
        return result;
      }
    },
    refetchIntervalInBackground: 60000 * 10,
    backgroundFetch: true,
    priority: 5,
    enabled: false,
  });

  const data_source_by_uuid = useQuery({
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_first_fetch",
      },
    ],
    queryFn: ({ signal }) => {
      const result = getDataSourceById(ds?.uuid);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 5,
    enabled: false,
  });

  //consent check
  useDebounce(
    () => {
      if (
        !!account &&
        !data_source_by_uuid_finapi_connections.isFetching &&
        data_source_by_uuid_finapi_connections.data
      ) {
        if (!global.isConsentAlert) {
          global.isConsentAlert = [];
        }
        let foundInDataset = datasetAccountList?.find(
          (o1) => o1.uuid === ds?.uuid
        );
        if (
          data_source_by_uuid_finapi_connections?.data !== "COMPLETED" &&
          data_source_by_uuid_finapi_connections?.data !== "UPDATED" &&
          Boolean(foundInDataset)
        ) {
          if (!global.isConsentAlert?.includes(ds.uuid)) {
            global.isConsentAlert.push(ds.uuid);
          }
        } else {
          global.isConsentAlert = global.isConsentAlert?.filter(
            (o1) => o1 !== ds?.uuid
          );
        }
        dispatch(setConsentAlert(global.isConsentAlert?.length));
      }
    },
    2000,
    [account, ds, data_source_by_uuid_finapi_connections, datasetAccountList],
    true
  );

  //fetch connections
  useDebounce(
    () => {
      if (
        !!account &&
        !data_source_by_uuid_finapi_connections?.data &&
        !data_source_by_uuid_finapi_connections?.isFetching
      ) {
        data_source_by_uuid_finapi_connections?.refetch();
      }
    },
    200,
    [account, ds, data_source_by_uuid_finapi_connections, addFrom],
    true
  );

  //first interval fetch and check
  useEffect(() => {
    if (
      !!account &&
      ds.state === 1 &&
      !ds?.last_sync_date &&
      !data_source_by_uuid?.data?.last_sync_date &&
      !data_source_by_uuid_finapi_connections?.isFetching &&
      (data_source_by_uuid_finapi_connections?.data === "COMPLETED" ||
        data_source_by_uuid_finapi_connections?.data === "UPDATED")
    ) {
      if (!global.isFirstFetchUuid) {
        global.isFirstFetchUuid = [];
      }
      if (!global.isFirstFetchUuid?.includes(ds.uuid)) {
        global.isFirstFetchUuid.push(ds.uuid);
      }

      dispatch(setIsFirstFetchDs(global.isFirstFetchUuid?.length || 0));
      subscribe.current = setInterval(() => {
        data_source_by_uuid.refetch();
      }, 4000);
    }

    return () => {
      clearInterval(subscribe.current);
    };
  }, [
    account,
    ds,
    data_source_by_uuid_finapi_connections?.data,
    data_source_by_uuid_finapi_connections?.isFetching,
  ]);

  //first fetch complete
  useDebounce(
    () => {
      if (
        data_source_by_uuid?.data &&
        data_source_by_uuid?.isSuccess &&
        data_source_by_uuid?.data?.state === 1 &&
        data_source_by_uuid?.data?.last_sync_date
      ) {
        clearInterval(subscribe.current);
        if (profile?.first_login) {
          updateProfile({ first_login: false });
        }
        let updatedData = [];
        dataSource?.forEach((item) => {
          if (item?.uuid === data_source_by_uuid?.data?.uuid) {
            updatedData.push(data_source_by_uuid?.data);
          } else {
            updatedData.push(item);
          }
        });
        global.isFirstFetchUuid = global.isFirstFetchUuid?.filter(
          (o1) => o1 !== data_source_by_uuid?.data?.uuid
        );
        dispatch(setIsFirstFetchDs(global?.isFirstFetchUuid?.length || 0));
        dispatch(setAddFrom(null));
        dispatch(setDataSource(updatedData));
        refetchData();
      }

      if (data_source_by_uuid?.data && data_source_by_uuid?.isError) {
        clearInterval(subscribe.current);
      }
    },
    200,
    [data_source_by_uuid?.data, data_source_by_uuid?.status],
    true
  );

  return null;
};

const IntegrationsDs = ({
  ds,
  getDataSourceById,
  updateProfile,
  refetchData,
}) => {
  let subscribe = useRef(null);
  const prev_last_sync_date = useRef(null);
  // if (ds?.title === "Excel import") {
  //   console.log("🚀 / prev_last_sync_date:", prev_last_sync_date.current);
  // }
  const dispatch = useDispatch();
  const addFrom = useSelector((state) => state.settingsSlice.addFrom);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  const isDsUuidLoading = useSelector(
    (state) => state.globalSlice.isDsUuidLoading
  );
  const isDsUuidUpdating = useSelector(
    (state) => state.globalSlice.isDsUuidUpdating
  );
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const check_is_connected =
    ds?.note !== "Google Sheets API" && ds?.note !== "Excel import";

  //query
  const data_source_by_uuid_external_connections = useQuery({
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_external_connections",
      },
    ],
    queryFn: async ({ signal }) => {
      const result = await getExternalDataSourceConnection(ds?.uuid);
      if (result) {
        return result;
      }
    },
    refetchIntervalInBackground: 60000 * 10,
    backgroundFetch: true,
    priority: 5,
    enabled: false,
  });

  const data_source_by_uuid = useQuery({
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_first_fetch",
      },
    ],
    queryFn: async ({ signal }) => {
      if (!ds?.uuid) {
        return null;
      }
      try {
        const result = await getDataSourceById(ds.uuid, { signal });
        return result;
      } catch (error) {
        return null;
      }
    },
    enabled: false,
  });

  //api
  const refetchAllTransaction = async (integration_id) => {
    await APICall(
      "post",
      EndPoints.integrations + `${integration_id}/external/fetch-all/`,
      {}
    )
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setTimeout(() => {
            data_source_by_uuid?.refetch();
          }, 0);
        }
      })
      .finally(() => {
        dispatch(setIsDsUuidLoading(null));
      });
  };

  //consent check
  useDebounce(
    () => {
      if (
        !isDsUuidUpdating &&
        !isDsUuidLoading &&
        !data_source_by_uuid_external_connections.isFetching &&
        data_source_by_uuid_external_connections.data
      ) {
        if (!global.isConsentAlert) {
          global.isConsentAlert = [];
        }
        let foundInDataset = datasetAccountList?.find(
          (o1) => o1.uuid === ds?.uuid
        );
        if (
          foundInDataset &&
          data_source_by_uuid_external_connections?.data !== "UPDATED"
        ) {
          if (!global.isConsentAlert?.includes(ds.uuid)) {
            global.isConsentAlert.push(ds.uuid);
          }
        } else {
          global.isConsentAlert = global.isConsentAlert?.filter(
            (o1) => o1 !== ds?.uuid
          );
        }
        dispatch(setConsentAlert(global.isConsentAlert?.length));
      }
    },
    2000,
    [
      ds,
      data_source_by_uuid_external_connections,
      datasetAccountList,
      isDsUuidLoading,
      isDsUuidUpdating,
    ],
    true
  );

  //fetch connections
  useDebounce(
    () => {
      if (
        (!isDsUuidLoading ||
          (isDsUuidLoading && isDsUuidLoading === ds?.uuid)) &&
        !data_source_by_uuid_external_connections.data &&
        !data_source_by_uuid_external_connections.isFetching
      ) {
        data_source_by_uuid_external_connections.refetch();
      }
    },
    200,
    [ds, data_source_by_uuid_external_connections, isDsUuidLoading, addFrom],
    true
  );

  //first fetch
  useEffect(() => {
    if (
      data_source_by_uuid_external_connections?.isSuccess &&
      !data_source_by_uuid_external_connections?.isFetching &&
      data_source_by_uuid_external_connections?.data === "UPDATED" &&
      (isDsUuidLoading === ds?.uuid ||
        (ds?.state === 1 &&
          !ds?.last_sync_date &&
          (check_is_connected ? !ds?.is_connected : true) &&
          (check_is_connected
            ? !data_source_by_uuid?.data?.is_connected
            : true) &&
          !data_source_by_uuid?.data?.last_sync_date))
    ) {
      if (!global.isFirstFetchUuid) {
        global.isFirstFetchUuid = [];
      }
      if (!global.isFirstFetchUuid?.includes(ds.uuid)) {
        global.isFirstFetchUuid.push(ds.uuid);
      }

      dispatch(setIsFirstFetchDs(global.isFirstFetchUuid?.length || 0));
    }
  }, [
    ds,
    isDsUuidLoading,
    check_is_connected,
    data_source_by_uuid?.data?.is_connected,
    data_source_by_uuid?.data?.last_sync_date,
    data_source_by_uuid_external_connections?.data,
    data_source_by_uuid_external_connections.status,
    data_source_by_uuid_external_connections?.isSuccess,
    data_source_by_uuid_external_connections?.isFetching,
    dispatch,
  ]);

  //first interval fetch and check
  useEffect(() => {
    if (
      !data_source_by_uuid_external_connections?.isFetching &&
      data_source_by_uuid_external_connections?.data === "UPDATED" &&
      data_source_by_uuid?.data?.state === 1 &&
      data_source_by_uuid?.data?.is_connected &&
      (!data_source_by_uuid?.data?.last_sync_date ||
        (data_source_by_uuid?.data?.last_sync_date &&
          prev_last_sync_date.current ===
            data_source_by_uuid?.data?.last_sync_date))
    ) {
      if (!subscribe.current) {
        subscribe.current = setInterval(() => {
          data_source_by_uuid?.refetch();
        }, 4000);
      }
    }

    return () => {
      clearInterval(subscribe.current);
    };
  }, [
    data_source_by_uuid_external_connections?.isFetching,
    data_source_by_uuid_external_connections?.data,
    data_source_by_uuid,
  ]);

  //first fetch complete
  useDebounce(
    () => {
      if (
        data_source_by_uuid?.data &&
        data_source_by_uuid?.data?.state === 1 &&
        data_source_by_uuid?.data?.is_connected &&
        data_source_by_uuid?.data?.last_sync_date
      ) {
        if (
          prev_last_sync_date.current
            ? prev_last_sync_date.current !==
              data_source_by_uuid?.data?.last_sync_date
            : true
        ) {
          clearInterval(subscribe.current);
          prev_last_sync_date.current = null;
          if (profile?.first_login) {
            updateProfile({ first_login: false });
          }
          let updatedData = [];
          dataSource?.forEach((item) => {
            if (item?.uuid === data_source_by_uuid?.data?.uuid) {
              updatedData.push(data_source_by_uuid?.data);
            } else {
              updatedData.push(item);
            }
          });
          global.isFirstFetchUuid = global.isFirstFetchUuid?.filter(
            (o1) => o1 !== data_source_by_uuid?.data?.uuid
          );
          dispatch(setIsFirstFetchDs(global?.isFirstFetchUuid?.length || 0));
          dispatch(setAddFrom(null));
          dispatch(setDataSource(updatedData));
          getDataSourceErrorById(data_source_by_uuid?.data);
          refetchData();
        }
      }

      if (data_source_by_uuid?.data && data_source_by_uuid?.isError) {
        clearInterval(subscribe.current);
      }
    },
    200,
    [
      data_source_by_uuid?.data,
      data_source_by_uuid?.data?.is_connected,
      data_source_by_uuid?.data?.last_sync_date,
      data_source_by_uuid?.status,
    ],
    true
  );

  //final update
  useDebounce(
    () => {
      if (
        data_source_by_uuid_external_connections?.data === "UPDATED" &&
        data_source_by_uuid_external_connections?.isSuccess
      ) {
        if (isDsUuidLoading === ds?.uuid) {
          prev_last_sync_date.current = ds?.last_sync_date;
          clearInterval(subscribe.current);
          refetchAllTransaction(ds?.uuid);
        }
        if (isDsUuidUpdating === ds?.uuid) {
          clearInterval(subscribe.current);
          dispatch(setIsDsUuidUpdating(null));
        }
      }
      if (
        data_source_by_uuid_external_connections?.data === "DISCONNECTED" &&
        data_source_by_uuid_external_connections?.isSuccess
      ) {
        if (isDsUuidLoading === ds?.uuid) {
          dispatch(setIsDsUuidLoading(null));
        }
        if (isDsUuidUpdating === ds?.uuid) {
          dispatch(setIsDsUuidUpdating(null));
        }
      }
    },
    300,
    [
      ds?.uuid,
      ds?.last_sync_date,
      isDsUuidLoading,
      data_source_by_uuid_external_connections?.data,
      data_source_by_uuid_external_connections?.status,
    ],
    true
  );

  return null;
};
