import {
  eachQuarterOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  format,
  isPast,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getAllSubCategoryIds,
  getCategoryParentId,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import LineCharts from "../../../../components/Charts/LineCharts";
import BarCharts from "../../../../components/Charts/BarCharts";
import EmptyView from "./Component/EmptyView";
import store from "../../../../store";

const RangeType = "Monthly";

const BarChartWidget = ({ type, chartType, widgetType, widget }) => {
  //state
  const [data, setData] = useState([]);
  const [barsData, setBarsData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  let formateString = "yyyy-MM-dd";

  if (RangeType === "Monthly") {
    formateString = "MMM-yyyy";
  }
  if (RangeType === "Quarterly") {
    formateString = "QQQ, yyyy";
  }
  if (RangeType === "Annually") {
    formateString = "yyyy";
  }

  return (
    <div style={{ height: "90%", width: "100%" }}>
      <LogicFunctions
        widget={widget}
        setData={setData}
        widgetType={widgetType}
        setIsFetching={setIsFetching}
        setLoading={setLoading}
        type={type}
        setBarsData={setBarsData}
        chartType={chartType}
        formateString={formateString}
        RangeType={RangeType}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <div style={{ height: "100%", width: "100%" }}>
          {chartType === "Bars" ? (
            <BarCharts
              data={data}
              hideDefault
              dynamicColor={type === 0}
              yAxisProps={{
                dataKey: undefined,
              }}
              barsData={barsData}
              currentMonth={format(new Date(), formateString)}
            />
          ) : null}
          {chartType === "Line" ? (
            <LineCharts
              data={data}
              type={type}
              barsData={barsData}
              dynamicColor={type === 0}
              currentMonth={format(new Date(), formateString)}
            />
          ) : null}
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default BarChartWidget;

const LogicFunctions = ({
  setData,
  setIsFetching,
  setLoading,
  setBarsData,
  widget,
  widgetType,
  type,
  chartType,
  formateString,
  RangeType,
}) => {
  const theme = useTheme();

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const BookedId = stateByTitle?.["Booked"]?.[0]?.uuid;
  const BaseId = scenarioByTitle?.["Base"]?.[0]?.uuid;

  const FilteredWidgetCategories = useMemo(() => {
    let array = [];
    widget?.categories?.forEach((cat) => {
      getAllSubCategoryIds({ array, id: cat });
    });
    return array;
  }, [widget?.categories]);

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        widget: {
          start_date: widget?.start_date,
          end_date: widget?.end_date,
        },
        widgetType,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        multiStatesIds: [BookedId],
        multiScenarioIds: [BaseId],
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled:
      !!widget?.start_date &&
      !!widget?.end_date &&
      !!dataSetData?.uuid &&
      !!BookedId &&
      !!BaseId,
    priority: 3,
  });

  useEffect(() => {
    setIsFetching(WidgetData?.isFetching);
    if (WidgetData?.isFetching) {
      setLoading(true);
    }
  }, [WidgetData?.isFetching]);

  useDebounce(
    () => {
      if (!WidgetData?.isFetching && WidgetData?.data) {
        let dummyData = [];
        setLoading(true);
        const selectionCategoriesByID =
          store.getState().categorySlice?.selectionCategoriesByID;
        let resultMain = [];
        // let resultRef = [];
        if (RangeType === "Monthly") {
          resultMain = eachMonthOfInterval({
            start: new Date(widget?.start_date),
            end: new Date(widget?.end_date),
          });
          // resultRef = eachMonthOfInterval({
          //   start: new Date(start_date),
          //   end: new Date(widget?.end_date),
          // });
        }
        if (RangeType === "Quarterly") {
          resultMain = eachQuarterOfInterval({
            start: new Date(widget?.start_date),
            end: new Date(widget?.end_date),
          });
          // resultRef = eachQuarterOfInterval({
          //   start: new Date(start_date),
          //   end: new Date(widget?.end_date),
          // });
        }
        if (RangeType === "Annually") {
          resultMain = eachYearOfInterval({
            start: new Date(widget?.start_date),
            end: new Date(widget?.end_date),
          });
          // resultRef = eachYearOfInterval({
          //   start: new Date(start_date),
          //   end: new Date(end_date),
          // });
        }
        let chartKeyArray = [];
        const periodTranData = WidgetData?.data?.results || [];
        const periodData = _.groupBy(periodTranData, ({ month }) =>
          format(new Date(month), formateString)
        );
        const total_key = "report_total_key";
        const categoryParent = getCategoryParentId();
        const filterCategory = periodTranData?.filter(
          (item) =>
            item?.category &&
            (FilteredWidgetCategories?.length > 0
              ? FilteredWidgetCategories.includes(item?.category)
              : true) &&
            (type === 0
              ? true
              : type === 1
                ? item?.inflow >= 0
                : item?.outflow < 0)
        );
      
        let categories = [];
        if (FilteredWidgetCategories?.length === 0) {
          categories.push("Uncategorized");
        }
        filterCategory?.forEach((item) => {
          const category = selectionCategoriesByID?.[item?.category]?.[0];
          if (category?.parent && widget.categories?.length > 0) {
            if (!categories.includes(category?.uuid)) {
              categories.push(category?.uuid);
            }
          } else {
            if (!categories.includes(categoryParent?.[category?.uuid])) {
              categories.push(categoryParent?.[category?.uuid]);
            }
          }
        });

        if (periodTranData?.length > 0) {
          resultMain?.forEach((element) => {
            let date = format(new Date(element), formateString);
            let obj = { due_date: date };

            const item = periodData?.[date] ?? [];
            if (chartType === "Bars" && type !== 0) {
              const TransactionsByCategory = _.groupBy(item, ({ category }) =>
                category
                  ? selectionCategoriesByID?.[category]?.[0]?.parent &&
                    widget.categories?.length > 0
                    ? category
                    : categoryParent?.[category]
                  : "Uncategorized"
              );

              categories?.forEach((key) => {
                let total = 0;
                if (type === 0) {
                  total = (TransactionsByCategory?.[key] || [])?.reduce(
                    (total, entry) =>
                      parseFloat(total) +
                      parseFloat(entry?.outflow ?? 0) +
                      parseFloat(entry?.inflow ?? 0),
                    0
                  );
                }
                if (type === 1) {
                  total = (TransactionsByCategory?.[key] || [])?.reduce(
                    (total, entry) =>
                      parseFloat(total) + parseFloat(entry?.inflow ?? 0),
                    0
                  );
                }
                if (type === 2) {
                  total = (TransactionsByCategory?.[key] || [])?.reduce(
                    (total, entry) =>
                      parseFloat(total) +
                      parseFloat(Math.abs(entry?.outflow ?? 0)),
                    0
                  );
                }

                obj[key] = parseFloat(total)?.toFixed(0);
              });
              Object.keys(obj).forEach((key) => {
                if (key !== "due_date" && key !== total_key) {
                  obj[total_key] =
                    Number(obj?.[total_key] || 0) + Number(obj?.[key] || 0);
                }
              });
            } else {
              let total = 0;
              const filterData = item?.filter((item) =>
                FilteredWidgetCategories?.length > 0
                  ? FilteredWidgetCategories.includes(item?.category)
                  : true
              );
              if (type === 0) {
                total = filterData?.reduce(
                  (total, entry) =>
                    parseFloat(total) +
                    parseFloat(entry?.outflow ?? 0) +
                    parseFloat(entry?.inflow ?? 0),
                  0
                );
              }
              if (type === 1) {
                total = filterData?.reduce(
                  (total, entry) =>
                    parseFloat(total) + parseFloat(entry?.inflow ?? 0),
                  0
                );
              }
              if (type === 2) {
                total = filterData?.reduce(
                  (total, entry) =>
                    parseFloat(total) +
                    parseFloat(Math.abs(entry?.outflow ?? 0)),
                  0
                );
              }
              if (isPast(element)) {
                obj.gross_value = parseFloat(total)?.toFixed(0);
              }
            }

            dummyData.push(obj);
          });
          if (chartType === "Bars" && type !== 0) {
            categories?.forEach((category_uuid) => {
              const category = selectionCategoriesByID?.[category_uuid]?.[0];

              if (category && type === 1 && category?.type !== 1) {
                return;
              }
              if (category && type === 2 && category?.type !== 2) {
                return;
              }
              chartKeyArray.push({
                key: category?.title || "Uncategorized",
                color: category?.color || theme.palette.color.slate[600],
                dataKey: category_uuid || "Uncategorized",
                stackId: "a",
                fill: category?.color || theme.palette.color.slate[600],
                shade: 800,
              });
            });
            chartKeyArray.push({
              key: total_key,
              color: theme.palette.color.slate[500],
              dataKey: total_key,
              stackId: "a",
              fill: theme.palette.color.slate[500],
              shade: 800,
              hideBar: true,
              isBold: true,
              showTopSeparator: true,
            });
          } else {
            chartKeyArray.push({
              key: "Gross value",
              dataKey: "gross_value",
              stackId: "a",
              fill:
                type === 0
                  ? theme.palette.color.purple[600]
                  : type === 1
                    ? theme.palette.color.green[600]
                    : theme.palette.color.red[600],
              stroke:
                type === 0
                  ? "url(#splitColor)"
                  : type === 1
                    ? theme.palette.color.green[600]
                    : theme.palette.color.red[600],
              color: type === 0 ? "purple" : type === 1 ? "green" : "red",
              strokeWidth: 2,
            });
          }
        }

        setTimeout(() => {
          setLoading(false);
          setData(dummyData);
          setBarsData(chartKeyArray);
        }, 250);
      }
    },
    300,
    [
      WidgetData?.data,
      WidgetData?.isFetching,
      type,
      selectionCategories,
      FilteredWidgetCategories,
      chartType,
    ],
    true
  );

  return null;
};
