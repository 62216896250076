import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import OverlayFooter from "../../../../../components/Overlay/OverlayFooter";
import HeaderMappingView from "../../HeaderMappingView";
import { Constant } from "../../../../../Helper";

const requiredFields = ["id", "title", "gross_value", "due_date"];

const MatchColumnsStep = ({ state, onSave, onBack }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { headerValues } = state;

  //state
  const [errors, setErrors] = useState(null);
  const [dropdownValues, setDropdownValues] = useState(
    Constant.FinbanMappingFields
  );

  //functions
  const onNext = useCallback(async () => {
    const newErrors = {};
    requiredFields.forEach((key) => {
      if (!dropdownValues[key]) {
        newErrors[key] = "This field is required";
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const headers = Object.keys(dropdownValues).reduce((acc, key) => {
        const value = dropdownValues[key];
        if (value) {
          acc[value] = key;
        }
        return acc;
      }, {});
      await onSave(headers);
    }
  }, [dropdownValues, onSave]);

  return (
    <Box sx={{ height: "100%" }}>
      <Typography
        variant="h4"
        fontWeight={"fontWeightBold"}
        color={theme.palette.color.slate[800]}
        sx={{
          mb: "1.5rem",
        }}
      >
        {t(state?.step?.title)}
      </Typography>
      <HeaderMappingView
        errors={errors}
        setErrors={setErrors}
        dropdownValues={dropdownValues}
        setDropdownValues={setDropdownValues}
        headersFromSheet={headerValues}
        fileColumnHeader="excel_header_mapping_file_column"
        // headersSetByUser={headersSetByUser}
      />
      <Stack
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          px: "1.75rem",
          py: "1rem",
          backgroundColor: theme.palette.color.slate[100],
        }}
      >
        <OverlayFooter
          textAdd="Save"
          textCancel="Back"
          addIcon={null}
          cancelIcon={null}
          onCancel={onBack}
          onClickAction={onNext}
          addButtonSx={{
            py: "0.75rem",
          }}
          cancelButtonSx={{
            py: "0.75rem",
          }}
        />
      </Stack>
    </Box>
  );
};

export default MatchColumnsStep;
