import {
  differenceInCalendarDays,
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachDayOfInterval,
  getWeekOfMonth,
  subMonths,
  isValid,
  setDate,
  addDays,
  format,
  parse,
} from "date-fns";
import {
  FaRegPaperPlane,
  FaRegThumbsDown,
  FaRegThumbsUp,
  FaPaperPlane,
  FaCheck,
} from "react-icons/fa";
import {
  TbChartAreaLineFilled,
  TbBuildingBank,
  TbCircleMinus,
  TbCircles,
} from "react-icons/tb";
import { AiOutlineDollarCircle, AiOutlineEuroCircle, AiOutlinePound } from "react-icons/ai";
import { BsCalendarEventFill, BsExclamation } from "react-icons/bs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AssistantIcon from "@mui/icons-material/Assistant";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { formatValue } from "react-currency-input-field";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import AddchartIcon from "@mui/icons-material/Addchart";
import CategoryIcon from "@mui/icons-material/Category";
import { BiCircleThreeQuarter } from "react-icons/bi";
import PercentIcon from "@mui/icons-material/Percent";
import DeleteIcon from "@mui/icons-material/Delete";
import CommitIcon from "@mui/icons-material/Commit";
import { RiFolderChartLine } from "react-icons/ri";
import { PiUsersThreeBold } from "react-icons/pi";
import { GoDot, GoDotFill } from "react-icons/go";
import { HiOutlineArchive } from "react-icons/hi";
import { de, enUS } from "date-fns/locale";
import { IoPeople } from "react-icons/io5";
import EventEmitter from "eventemitter3";
import { alpha } from "@mui/material";
import pLimit from "p-limit";

import EndPoints from "../APICall/EndPoints";
import APICall from "../../src/APICall";
import Icon from "../components/Icon";
import { Constant } from "./Constant";
import { queryClient } from "../App";
import { Color } from "./Color";
import store from "../store";
import i18n from "../i18n";

export const CurrencyPrefix = {
  EUR: "€",
  USD: "$",
  GBP: "£",
};
export const CurrencyPrefixIcon = {
  EUR: <AiOutlineEuroCircle />,
  USD: <AiOutlineDollarCircle />,
  GBP: <AiOutlinePound />,
};
let initialData = {
  tasks: {
    "task-1": { id: "task-1", content: "Take out the garbage" },
    "task-2": { id: "task-2", content: "Watch my favorite show" },
    "task-3": { id: "task-3", content: "Charge my phone" },
    "task-4": { id: "task-4", content: "Cook dinner" },
  },

  ResetAllDataOptions: [
    "transactions",
    "List",
    "integrations",
    "invoices",
    "rules",
    "reports",
  ],

  //acounts
  account_Type: [
    {
      value: "UNDEFINED",
      display_name: "Type undefined",
    },
    {
      value: "CHECKING",
      display_name: "Checking account",
    },
    {
      value: "SAVINGS",
      display_name: "Savings account",
    },
    {
      value: "CREDIT_CARD",
      display_name: "Credit card account",
    },
    {
      value: "INVESTMENT",
      display_name: "Investment",
    },
    {
      value: "CASH",
      display_name: "Cash",
    },
    {
      value: "Security",
      display_name: "Security",
    },
    {
      value: "Loan",
      display_name: "Loan",
    },
    {
      value: "Membership",
      display_name: "Membership",
    },
    {
      value: "Bausparen",
      display_name: "Bausparen",
    },
  ],

  //table
  gridHeaderRow: {
    rowId: "header",
    cells: [
      { type: "header", text: "" },
      { id: "1", type: "header", text: "Jan" },
      { id: "2", type: "header", text: "Feb" },
      { id: "3", type: "header", text: "Mar" },
      { id: "4", type: "header", text: "Apr" },
      { id: "5", type: "header", text: "May" },
      { id: "6", type: "header", text: "Jun" },
      { id: "7", type: "header", text: "Jul" },
      { id: "8", type: "header", text: "Aug" },
      { id: "9", type: "header", text: "Sep" },
      { id: "10", type: "header", text: "Oct" },
      { id: "11", type: "header", text: "Nov" },
      { id: "12", type: "header", text: "Dec" },
      { type: "header", text: "Totals" },
    ],
  },
  currencies: [
    {
      uuid: 1,
      code: "USD",
      name: "currency_dollar",
      symbol: "$",
    },
    {
      uuid: 2,
      code: "EUR",
      name: "currency_euro",
      symbol: "€",
    },
    {
      uuid: 3,
      code: "GBP",
      name: "currency_pound",
      symbol: "£",
    },
  ],
  datasetType: [
    {
      value: "agency",
      display_name: "Agency",
    },
    {
      value: "sass",
      display_name: "SaaS",
    },
    {
      value: "general",
      display_name: "General",
    },
  ],

  //list
  actionListOption: [
    {
      uuid: 4,
      title: "Set State",
      icon: (
        <Icon icon={<TbCircles />} fontSize={"1.3rem"} color="inherit"></Icon>
      ),
    },
    {
      uuid: 8,
      title: "Set Scenario",
      icon: (
        <Icon
          icon={<TbChartAreaLineFilled />}
          fontSize={"1.3rem"}
          color="inherit"
        ></Icon>
      ),
    },
    {
      uuid: 14,
      title: "Set Tax",
      icon: <PercentIcon />,
    },
    { uuid: 3, title: "Categorize", icon: <LocalOfferIcon /> },
    { uuid: 5, title: "Uncategorize", icon: <LocalOfferIcon /> },
    { uuid: 6, title: "Ignored", icon: <VisibilityOffIcon /> },
    { uuid: 13, title: "Booked", icon: <VisibilityIcon /> },
    {
      uuid: 7,
      title: "Add Cost Unit",
      icon: (
        <Icon
          icon={<RiFolderChartLine />}
          fontSize={"1.3rem"}
          color="inherit"
        ></Icon>
      ),
    },
    {
      uuid: 9,
      title: "Add Due Date",
      icon: <CalendarMonthIcon />,
    },
    {
      uuid: 10,
      title: "Add Invoice Date",
      icon: <CalendarMonthIcon />,
    },
    {
      uuid: 12,
      title: "Apply all suggestions",
      icon: <AssistantIcon />,
    },
    { uuid: 1, title: "Delete", icon: <DeleteIcon /> },
    { uuid: 2, title: "Duplicate", icon: <ContentCopyIcon /> },
  ],

  //Profile
  euro_countries: [
    {
      locale: "de_AT",
      language: "German (Austria)",
      market: "AT",
      country: "Austria",
    },
    {
      locale: "be_BE",
      language: "Belgian (Dutch)",
      market: "BE",
      country: "Belgium",
    },
    // {
    //   locale: "bg_BG",
    //   language: "Bulgarian",
    //   market: "BG",
    //   country: "Bulgaria",
    // },
    // {
    //   locale: "cs_CZ",
    //   language: "Czech",
    //   market: "CZ",
    //   country: "Czech Republic",
    // },
    {
      locale: "da_DK",
      language: "Danish",
      market: "DK",
      country: "Denmark",
    },
    {
      locale: "de_DE",
      language: "German",
      market: "DE",
      country: "Germany",
    },
    {
      locale: "en_GB",
      language: "English",
      market: "GB",
      country: "United Kingdom",
    },
    {
      locale: "et_EE",
      language: "Estonian",
      market: "EE",
      country: "Estonia",
    },
    {
      locale: "ga_IE",
      language: "Irish",
      market: "IE",
      country: "Ireland",
    },
    // {
    //   locale: "el_GR",
    //   language: "Greek",
    //   market: "GR",
    //   country: "Greece",
    // },
    {
      locale: "es_ES",
      language: "Spanish",
      market: "ES",
      country: "Spain",
    },
    {
      locale: "fr_FR",
      language: "French",
      market: "FR",
      country: "France",
    },
    // {
    //   locale: "hr_HR",
    //   language: "Croatian",
    //   market: "HR",
    //   country: "Croatia",
    // },
    {
      locale: "it_IT",
      language: "Italian",
      market: "IT",
      country: "Italy",
    },
    // {
    //   locale: "el_CY",
    //   language: "Greek (Cyprus)",
    //   market: "CY",
    //   country: "Cyprus",
    // },
    {
      locale: "lv_LV",
      language: "Latvian",
      market: "LV",
      country: "Latvia",
    },
    {
      locale: "lt_LT",
      language: "Lithuanian",
      market: "LT",
      country: "Lithuania",
    },
    // {
    //   locale: "lb_LU",
    //   language: "Luxembourgish",
    //   market: "LU",
    //   country: "Luxembourg",
    // },
    // {
    //   locale: "hu_HU",
    //   language: "Hungarian",
    //   market: "HU",
    //   country: "Hungary",
    // },
    // {
    //   locale: "mt_MT",
    //   language: "Maltese",
    //   market: "MT",
    //   country: "Malta",
    // },
    {
      locale: "nl_NL",
      language: "Dutch",
      market: "NL",
      country: "Netherlands",
    },

    {
      locale: "pl_PL",
      language: "Polish",
      market: "PL",
      country: "Poland",
    },
    {
      locale: "pt_PT",
      language: "Portuguese",
      market: "PT",
      country: "Portugal",
    },
    // {
    //   locale: "ro_RO",
    //   language: "Romanian",
    //   market: "RO",
    //   country: "Romania",
    // },
    // {
    //   locale: "sl_SI",
    //   language: "Slovenian",
    //   market: "SI",
    //   country: "Slovenia",
    // },
    // {
    //   locale: "sk_SK",
    //   language: "Slovak",
    //   market: "SK",
    //   country: "Slovakia",
    // },
    {
      locale: "fi_FI",
      language: "Finnish",
      market: "FI",
      country: "Finland",
    },
    {
      locale: "sv_SE",
      language: "Swedish",
      market: "SE",
      country: "Sweden",
    },
    // {
    //   locale: "de_CH",
    //   language: "German (Switzerland)",
    //   market: "CH",
    //   country: "Switzerland",
    // },
  ],
  ReportsMainTabOptions: [
    {
      id: 0,
      className: "dashboard_overview_1_step",
      path: ["/overview"],
      option: "Tab_Title_Overview",
    },
    {
      id: 4,
      // className: "dashboard_revenue__step",
      path: [
        "/revenue/overview",
        "/revenue/recurring-income",
        "/revenue/revenue-stream",
      ],
      option: "Tab_Title_Revenue",
    },
    {
      id: 5,
      // className: "dashboard_expenses_1_step",
      path: ["/expenses/overview", "/expenses/recurring-expenses"],
      option: "Tab_Title_Expenses",
    },
    // {
    //   id: 1,
    //   path: ["/balance-sheet"],
    //   option: "Tab_Title_balance_sheet",
    // },
    {
      id: 2,
      // className: "dashboard_revenue__step",
      path: [
        "/report/balance-sheet",
        "/report/Income-statement",
        "/report/success-report",
        "/report/cost-ratio&returns",
      ],
      option: "Tab_Title_Reports",
    },
    {
      id: 3,
      // className: "dashboard_employees_1_step",
      path: ["/staff"],
      option: "Tab_Title_Staff",
    },

    {
      id: 6,
      // className: "dashboard_overview_3_step",
      path: [
        "/customers/customers-portfolio-analysis",
        "/customers/top-customers",
        "/customers/customers-list",
        "/customers/merge-customers",
      ],
      option: "Tab_Title_Customers",
    },
    {
      id: 7,
      // className: "dashboard_suppliers_1_step",
      path: [
        "/suppliers/recurring-subscriptions",
        "/suppliers/suppliers-portfolio-analysis",
        "/suppliers/top-suppliers",
        "/suppliers/suppliers-list",
        "/suppliers/merge-suppliers",
      ],
      option: "Tab_Title_Suppliers",
    },
    // {
    //   id: 8,
    //   className: "dashboard_forecast_1_step",
    //   path: ["/forecast"],
    //   option: "Tab_Title_Forecast",
    // },
  ],
  cardActionList: [
    { uuid: 1, title: "Delete" },
    { uuid: 2, title: "Duplicate" },
    { uuid: 3, title: "Duplicate to next Month" },
    { uuid: 4, title: "Duplicate to Date" },
    { uuid: 5, title: "Categorize" },
    { uuid: 6, title: "Set Date" },
    { uuid: 7, title: "Set State" },
  ],

  //register
  reviews: [
    {
      profile: "https://finban.io/wp-content/uploads/2022/04/michl.jpeg",
      name: "Michael Barth",
      position: "UX lecturer at various universities",
      linkTitle: "",
      link: "https://www.2av.de/",
      review:
        "Growth is one of the major hurdles for traditional agencies. The order situation and resources should consistently interact. Scenarios can be easily compared and planned with finban. Planning ahead is no longer a guessing game, but backed by numbers.",
    },
    {
      profile:
        "https://finban.io/wp-content/uploads/2022/07/Direktkontakt-Volker-Rantz.jpeg",
      name: "Volker Rantz",
      position: "CEO",
      linkTitle: "Staff One",
      link: "https://www.staffeins.de/",
      review:
        "Keeping track of several companies and a large number of bank accounts was simply not possible without spending a lot of time. The customer portfolio analysis in particular was a real benefit for us.",
    },
    {
      profile:
        "https://finban.io/wp-content/uploads/2022/07/daniel_ittner.jpeg",
      name: "Daniel Ittner",
      position: "CEO",
      linkTitle: "Koehler & Ittner",
      link: "https://www.koehler-ittner.de/en/landingpage/",
      review:
        "We subscribed to some tools, but never really set them up, too complicated. With finban, we had analyzes ready after a few clicks.",
    },
    {
      profile: "https://finban.io/wp-content/uploads/2022/04/steff.jpeg",
      name: "Stephanie Krentz",
      position: "Board of Directors",
      linkTitle: "Humanism Foundation Berlin",
      link: "https://www.humanismus-stiftung.de/",
      review:
        "Analyzing and understanding the different incoming payments in order to draw conclusions for further campaigns is one of the most important aspects of fundraising. It's even fun with finban.",
    },
    {
      profile: "https://finban.io/wp-content/uploads/2022/04/alex.jpeg",
      name: "Alexander Keck",
      position: "Best-selling author",
      linkTitle: "Unternehmergold",
      link: "https://unternehmergold.de/",
      review:
        "Many entrepreneurs concentrate on their core competencies and neglect topics such as financial figures, cash flow, taxes or the legal form of the company. there is a lot of potential here. finban prepares the numbers to make better decisions.",
    },
    {
      profile:
        "https://finban.io/wp-content/uploads/2022/07/tom-schmiedel.1024x1024.jpg",
      name: "Tom Schmiedel",
      position: "Managing Partner",
      linkTitle: "Nordsonne Identity",
      link: "https://www.nordsonne.de/",
      review:
        "The finban customer portfolio analysis surprised us. With a few clicks and a few tweaks, we had some really amazing insights!",
    },
  ],
  path: {
    organization: "organization",
    organizations: "organizations",
    Organization: "Organization",
    Organizations: "Organizations",
  },

  //recur
  recurringTypeTabs: [
    {
      uuid: 0,
      value: "single",
      icon: <CommitIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_single",
      display_name: "label_recur_tab_single",
    },
    {
      uuid: 1,
      value: "simple",
      icon: <EventRepeatIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_simple",
      display_name: "label_recur_tab_simple",
    },

    {
      uuid: 9,
      value: "loan",
      icon: (
        <Icon
          icon={<TbBuildingBank />}
          fontSize={"1.2rem"}
          color={"inherit"}
          style={{ mr: { xs: 0, sm: "0.5rem" } }}
        ></Icon>
      ),
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_loan",
      display_name: "label_recur_tab_loan",
    },

    {
      uuid: 10,
      value: "leasing",
      icon: (
        <Icon
          icon={<TbBuildingBank />}
          fontSize={"1.2rem"}
          color={"inherit"}
          style={{ mr: { xs: 0, sm: "0.5rem" } }}
        ></Icon>
      ),
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_leasing",
      display_name: "label_recur_tab_leasing",
    },
    {
      uuid: 2,
      value: "employee",
      icon: (
        <Icon
          icon={<IoPeople />}
          fontSize={"1.2rem"}
          color={"inherit"}
          style={{ mr: { xs: 0, sm: "0.5rem" } }}
        ></Icon>
      ),
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_employee",
      display_name: "label_recur_tab_employee",
    },
    {
      uuid: 3,
      value: "advanced",
      icon: <AddchartIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_advanced",
      display_name: "label_recur_tab_advanced",
    },

    {
      uuid: 4,
      value: "subscription",
      icon: (
        <Icon
          icon={<PiUsersThreeBold />}
          fontSize={"1.2rem"}
          color={"inherit"}
          style={{ mr: { xs: 0, sm: "0.5rem" } }}
        ></Icon>
      ),
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_subscription",
      display_name: "label_recur_tab_subscription",
    },
    {
      uuid: 5,
      value: "shop",
      icon: <ShoppingCartIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_shop",
      display_name: "label_recur_tab_shop",
    },
    {
      uuid: 6,
      value: "products",
      icon: <CategoryIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_products",
      display_name: "label_recur_tab_products",
    },
    {
      uuid: 7,
      value: "client",
      icon: <PeopleAltIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_client",
      display_name: "label_recur_tab_client",
    },
    {
      uuid: 8,
      value: "time",
      icon: <HourglassTopIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_time",
      display_name: "label_recur_tab_time",
    },
  ],

  //general
  calculationExcludeStates: [
    // "Offer partially calculated",
    // "Partially paid",
    "Offer calculated",
    "Offer archived",
    "Offer rejected",
    "Invoice paid",
    "Booked",
    "Ignored",
    "Written-off",
    "On-hold",
    "Canceled",
    "Pending",
    "Undefined",
    "Refunded",
    "Partially refunded",
  ],
  calculationExcludeStates2: [
    // "Offer partially calculated",
    // "Partially paid",
    "Offer calculated",
    "Offer archived",
    "Offer rejected",
    "Invoice paid",
    "Ignored",
    "Written-off",
    "On-hold",
    "Canceled",
    "Pending",
    "Undefined",
    "Refunded",
    "Partially refunded",
  ],
  excludeForOpenState: [
    "Planned",
    "Booked",
    // "Ignored",
    // "Written-off",
    // "On-hold",
    // "Canceled",
    "Pending",
    "Undefined",
    // "Refunded",
    // "Partially refunded",
  ],
  openState: [
    "Lead - potential",
    "Lead - won",
    "Offer draft",
    "Offer sent",
    "Offer accepted",
    "Open",
    "Invoice sent",
    "Invoice open",
  ],
  defaultState: {
    Planned: {
      position: 1,
      color: "purple",
      title: "Planned",
      bg: "transparent",
      icon: <BsCalendarEventFill />,
      fontSize: "10px",
      margin: "3px",
    },
    "Lead - potential": {
      position: 2,
      color: "orange",
      title: "Lead - potential",
      bg: "transparent",
      icon: <GoDot />,
      fontSize: "14px",
      margin: "1px",
    },
    "Lead - won": {
      position: 3,
      color: "orange",
      title: "Lead - won",
      bg: "transparent",
      icon: <GoDotFill />,
      fontSize: "14px",
      margin: "1px",
    },
    "Offer draft": {
      position: 4,
      title: "Offer draft",
      color: "sky",
      bg: "transparent",
      icon: <FaRegPaperPlane />,
      fontSize: "12px",
      paddingRight: "1.2px",
    },
    "Offer sent": {
      position: 5,
      title: "Offer sent",
      color: "sky",
      bg: "transparent",
      icon: <FaPaperPlane />,
      fontSize: "12px",
      paddingRight: "1.2px",
    },
    "Offer accepted": {
      position: 6,
      title: "Offer accepted",
      color: "sky",
      bg: "transparent",
      icon: <FaRegThumbsUp />,
      fontSize: "12px",
      paddingLeft: "1px",
    },
    "Offer partially calculated": {
      position: 7,
      title: "Offer partially calculated",
      color: "sky",
      bgShade: 200,
      bg: "transparent",
      icon: <BiCircleThreeQuarter />,
      fontSize: "12px",
      // paddingRight: "0.8px",
    },
    "Offer calculated": {
      position: 8,
      title: "Offer calculated",
      color: "sky",
      bgShade: 200,
      bg: "transparent",
      icon: <FaCheck />,
      fontSize: "12px",
    },
    "Offer archived": {
      position: 9,
      title: "Offer archived",
      color: "sky",
      bgShade: 200,
      bg: "transparent",
      icon: <HiOutlineArchive />,
      fontSize: "12px",
      paddingLeft: "0.8px",
    },
    "Offer rejected": {
      position: 10,
      title: "Offer rejected",
      color: "sky",
      bgShade: 200,
      bg: "transparent",
      icon: <FaRegThumbsDown />,
      fontSize: "12px",
      paddingLeft: "1.5px",
      paddingTop: "1px",
    },
    Open: {
      position: 11,
      color: "green",
      title: "Open",
      bg: "transparent",
      icon: <GoDot />,
      fontSize: "14px",
      margin: "1px",
    },
    "Invoice draft": {
      position: 12,
      title: "Invoice draft",
      color: "green",
      bg: "transparent",
      icon: <FaRegPaperPlane />,
      fontSize: "12px",
      paddingRight: "1.2px",
    },
    "Invoice sent": {
      position: 13,
      title: "Invoice sent",
      color: "green",
      bg: "transparent",
      icon: <FaPaperPlane />,
      fontSize: "12px",
      paddingRight: "1.2px",
    },
    "Invoice open": {
      position: 14,
      title: "Invoice open",
      color: "green",
      bg: "transparent",
      icon: <FaPaperPlane />,
      fontSize: "12px",
      paddingRight: "1.2px",
    },
    "Partially paid": {
      position: 15,
      title: "Partially paid",
      color: "green",
      bgShade: 200,
      bg: "transparent",
      icon: <TbCircleMinus />,
      fontSize: "12px",
      paddingLeft: "0.8px",
    },
    "Invoice paid": {
      position: 16,
      title: "Invoice paid",
      color: "green",
      bgShade: 200,
      bg: "transparent",
      icon: <FaCheck />,
      fontSize: "12px",
      paddingLeft: "1px",
    },
    Booked: {
      position: 17,
      title: "Booked",
      color: "green",
      // iconColor: "#fff",
      bgShade: 200,
      icon: <FaCheck />,
      fontSize: "12px",
      paddingLeft: "2px",
      paddingRight: "1.5px",
      paddingTop: "1px",
    },
    Ignored: {
      position: 18,
      title: "Ignored",
      color: "slate",
      bg: "transparent",
      icon: <TbCircleMinus />,
      fontSize: "12px",
      paddingLeft: "0.4px",
    },
    "Written-off": {
      position: 19,
      title: "Written-off",
      color: "slate",
      bg: "transparent",
      icon: <TbCircleMinus />,
      fontSize: "12px",
      paddingLeft: "0.8px",
    },
    "On-hold": {
      position: 20,
      title: "On-hold",
      color: "slate",
      bg: "transparent",
      icon: <TbCircleMinus />,
      fontSize: "12px",
      paddingLeft: "0.8px",
    },
    Canceled: {
      position: 21,
      title: "Canceled",
      color: "slate",
      bg: "transparent",
      icon: <TbCircleMinus />,
      fontSize: "12px",
      paddingLeft: "0.8px",
    },
    Pending: {
      position: 22,
      title: "Pending",
      color: "red",
      iconColor: "red",
      bg: "transparent",
      icon: <BsExclamation />,
      fontSize: "12px",
      paddingLeft: "1.5px",
    },
    Undefined: {
      position: 23,
      title: "Undefined",
      color: "red",
      iconColor: "red",
      bg: "transparent",
      icon: <BsExclamation />,
      fontSize: "12px",
      paddingLeft: "1.5px",
    },
    Refunded: {
      position: 24,
      title: "Refunded",
      color: "pink",
      bg: "transparent",
      icon: <TbCircleMinus />,
      fontSize: "12px",
      paddingLeft: "0.4px",
    },
    "Partially refunded": {
      position: 25,
      title: "Partially refunded",
      color: "pink",
      bg: "transparent",
      icon: <TbCircleMinus />,
      fontSize: "12px",
      paddingLeft: "0.4px",
    },
  },
  defaultScenario: {
    Base: {
      position: 0,
      color: "slate",
    },
    Best: {
      position: 1,
    },
  },
  bookedState: ["Invoice paid", "Booked", "Undefined", "Pending"],
  plannedState: ["Planned"],
};
export default initialData;

export const thinScrollbarStyle = {
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "0.3rem",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: alpha(Color.themeColor, 0.1), //'#888',
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: alpha(Color.themeColor, 0.7), //'#555'
  },
};

export const getDefaultFilters = () => {
  return {
    // reports: {
    //   start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
    //   end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
    //   selectedStates: ["Booked"],
    //   selectedScenarios: [],
    //   client: {
    //     formate: {
    //       Income: ["top", "new", "trouble"],
    //       Expense: ["new", "trouble", "top"],
    //     },
    //     chartKey: {
    //       Income: [],
    //       Expense: [],
    //     },
    //     frequency: {
    //       Income: [],
    //       Expense: ["irregular", "daily", "weekly", "monthly", "yearly"],
    //     },
    //     stateValue: {
    //       Income: allStates,
    //       Expense: allStates,
    //     },
    //     scenariosValue: {
    //       Income: allScenarios,
    //       Expense: allScenarios,
    //     },
    //     selectedCategory: {
    //       Income: [],
    //       Expense: [],
    //     },
    //   },
    // },
    kanban: {
      precisionValue: 1,
      chart_keys: {},
      // viewValue: {
      //   Inflow: 12,
      //   Outflow: 12,
      // },
      // groupValue: {
      //   Inflow: 2,
      //   Outflow: 2,
      // },
      // stateValue: {
      //   Inflow: ["Booked"],
      //   Outflow: ["Booked"],
      // },
      // scenariosValue: {
      //   Inflow: ["Base"],
      //   Outflow: ["Base"],
      // },
      // intervalValue: 1,
      // scenarioValue: 2,

      // selectedStates: allStates,
      // selectedScenarios: allScenarios,
      // groupType: "scenarios",
      // indicatorType: "states",
      // selectedCostUnits: [],
      // selectedCategory: [],
    },
    list: {
      searchText: "",
      date_type: 1,
      start_date: null,
      end_date: null,
      typeName: ["Expense", "Income"],
      source: Constant.DefaultSource,
      toggle: ["diffValue"],
      selectedStates: [],
      selectedBankStates: [],
      selectedOpenStates: [],
      selectedScenarios: [],
      selectedCategory: [],
      selectedCostUnits: [],
      selectedDataSource: [],
    },
    staff: {
      staffSearchText: "",
      employeeType: [],
      employeeRole: [],
      employeeDepartment: [],
    },
  };
};

export const cloneDeep = (value) => {
  return JSON.parse(JSON.stringify(value));
};

export const getColor = (colorValue) => {
  let color = colorValue;
  if (!colorValue?.includes("#")) {
    let passData = [
      "green",
      "deepPurple",
      "lightBlue",
      "deepOrange",
      "blueGrey",
    ];
    let key = colorValue;
    if (!passData.includes(colorValue)) {
      key = colorValue?.toLowerCase();
    }
    color = Color[key];
  }
  return color;
};

export const getMuiColor = (colorValue = "pink", number = 500) => {
  let color = colorValue || "#b5c2c8";
  if (colorValue && !colorValue?.includes("#")) {
    if (Color["theme"][color]) {
      color = Color["theme"][color][number];
    } else {
      // color = Color["tailwind"][color][number];
    }
  } else {
    if (!number?.toString()?.includes("A")) {
      color = alpha(color, number / 2000);
    }
  }
  return color;
};

export const getTailwindColor = (colorValue, number = 600) => {
  let color = colorValue || "slate";
  if (color && Color["tailwind"][color] && !color?.includes("#")) {
    if (number === 25) {
      color = alpha(Color["tailwind"][color][50], 0.4);
    } else {
      color = Color["tailwind"][color][number];
    }
  } else if (color && color?.includes("#")) {
    color = alpha(color, number / 1000);
  }

  return color;
};

export const getFormattedDate = (date, formateString = "yyyy-MM-dd") => {
  if (!date) {
    return null;
  }
  const parsed = parse(date, formateString, new Date());
  if (isValid(parsed)) {
    return format(parsed, "yyyy-MM-dd");
  }
  return null;
};

export const formatDateToLocal = (date, formateString = "yyyy-MM-dd") => {
  if (!date) {
    return null;
  }
  const _date = new Date(date);
  const locale = store.getState()?.settingsSlice?.profile?.locale;
  if (_date) {
    return format(_date, formateString, {
      locale: locale === "de_DE" ? de : enUS,
    });
  }
  return null;
};

export const formatAmount = ({ amount = 0, hidePrefix, dataset }) => {
  const currencyFormate = store.getState()?.settingsSlice?.currencyFormate;
  const dataSetData = dataset || store.getState()?.boardSlice?.dataSetData;
  const formattedValue = formatValue({
    value: String(parseFloat(amount ?? "0").toFixed(0)),
    groupSeparator: currencyFormate?.groupSeparator,
    decimalSeparator: currencyFormate?.decimalSeparator,
    prefix: hidePrefix
      ? ""
      : CurrencyPrefix?.[dataSetData?.currency] || currencyFormate?.prefix,
  });
  return formattedValue;
};

export const convertFormatAmountToNumber = ({ amount = 0 }) => {
  const currencyFormate = store.getState()?.settingsSlice?.currencyFormate;
  let _amount = amount;
  Object.values(CurrencyPrefix)?.forEach((prefix) => {
    _amount = _amount?.replace(prefix, "");
  });
  return _amount
    .replaceAll(currencyFormate.groupSeparator, "")
    .replace(currencyFormate.decimalSeparator, ".")
    .replace(/[^0-9.-]+/, "")
    .trim();
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isValidTitle = ({ alreadyAddedTitles, title, count, node }) => {
  if (alreadyAddedTitles?.includes(title)) {
    count = count + 1;
    title = `${node?.title} ${count}`;
    return isValidTitle({ alreadyAddedTitles, title, count, node });
  }
  return title;
};

export const getCategoryParent = ({ id, type }) => {
  const selectionCategoriesByID =
    store.getState().categorySlice?.selectionCategoriesByID;
  const category = selectionCategoriesByID?.[id]?.[0];

  if (!category?.parent) {
    return category?.uuid;
  }

  const parent = getCategoryParent({ id: category?.parent, type });
  return parent;
};

export const getCategoryParentId = () => {
  const selectionCategoriesByID =
    store.getState().categorySlice?.selectionCategoriesByID;
  let categoryParent = {};
  Object.keys(selectionCategoriesByID)?.forEach((key) => {
    categoryParent[key] = getCategoryParent({ id: key });
  });
  return categoryParent;
};

export const getAllSubCategoryIds = ({ array, id }) => {
  const selectionCategoriesByID =
    store.getState().categorySlice?.selectionCategoriesByID;
  const category = selectionCategoriesByID?.[id]?.[0];
  array.push(category?.uuid);
  if (category?.children?.length > 0) {
    category?.children?.forEach((item) => {
      getAllSubCategoryIds({ array, id: item?.uuid });
    });
  }

  return array;
};

export let Emitter = new EventEmitter();

export const makeFunctionCalledOnce = function (fn) {
  let called = false;
  return function (...args) {
    if (!called) {
      called = true;
      fn.call(this, ...args);
    }
  };
};

export const similarity = (s1, s2, type = "string") => {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  let distance =
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  return type === "string" ? distance >= 0.8 : distance >= 0.9;
};

export const Similarity = (str1, str2, type = "string") => {
  if (type === "string") {
    // const set1 = new Set(str1.split(""));
    // const set2 = new Set(str2.split(""));
    // const intersection = new Set([...set1].filter((x) => set2.has(x)));
    // const union = new Set([...set1, ...set2]);
    // let distance = intersection.size / union.size;
    var longer = str1;
    var shorter = str2;
    if (str1.length < str2.length) {
      longer = str2;
      shorter = str1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    let distance =
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
    return distance >= 0.66;
  }
  if (type === "number") {
    const set1 = Number(str1);
    const set2 = Number(str2);
    const avg = (set1 + set2) / 2;
    const diff = Math.abs(set1 - set2);
    const relDiff = diff / avg;
    let distance = (1 - relDiff) * 100;
    return distance >= 99.8;
  }
};

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array([]);
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export const unique = (arr, props = []) => [
  ...new Map(
    arr.map((entry) => [props.map((k) => entry[k]).join("|"), entry])
  ).values(),
];

export const isMonthDate = (dateFrom, dateTo) => {
  let date1 = new Date(dateFrom);
  let date2 = new Date(dateTo);
  let month1 = date1.getMonth();
  let month2 = date2.getMonth();
  let year1 = date1.getYear();
  let year2 = date2.getYear();
  if (month1 === month2) {
    return false;
  }
  if (month1 === month2 + 1 && year1 === year2) {
    return true;
  }
  if (month1 === 0 && month2 === 11 && year1 === year2 + 1) {
    return true;
  }
  return false;
};

export const getShare = (Total, amount) => {
  let share = (amount / Total) * 100;
  return share?.toString() === "Infinity"
    ? 100
    : isNaN(share)
      ? 0
      : share?.toFixed(2) ?? 0;
};

export const getPercentageChange = (oldNumber, newNumber, type = "Income") => {
  var decreaseValue = parseFloat(newNumber) - parseFloat(oldNumber);
  let divide = 1;
  if (type === "Income") {
    divide =
      oldNumber === 0
        ? parseFloat(newNumber) < 0
          ? -1
          : 1
        : parseFloat(oldNumber);
  } else {
    divide =
      newNumber === 0.0
        ? parseFloat(oldNumber) < 0
          ? -1
          : 1
        : parseFloat(newNumber);
  }

  return ((decreaseValue / divide) * 100.0).toFixed(1);
};

export const randomColor = () => {
  return (
    "#" +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")
      .toUpperCase()
  );
};

export const truncate = (source, size = 12) => {
  return source?.length > size
    ? source?.slice(0, size - 1)?.trim("") + ".."
    : source;
};

export const updateLanguage = (value) => {
  let splittedLang = "";
  if (value) {
    splittedLang = value?.split("_");
  } else {
    let userLanguage = navigator.language || navigator.userLanguage;
    splittedLang = userLanguage?.split("-");
  }
  let lang = splittedLang?.[0];
  i18n.changeLanguage(lang === "de" ? lang : "en");
};

export const updateURLParams = (url, params) => {
  // Parse the URL
  var parser = document.createElement("a");
  parser.href = url;

  // Extract the existing query parameters
  var query = parser.search.substr(1);
  var queryParams = new URLSearchParams(query);

  // Update the query parameters with the new values
  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.set(key, params[key]);
    }
  }

  // Rebuild the URL with the updated query parameters
  parser.search = "?" + queryParams.toString();

  // Return the modified URL
  return parser.href;
};

export const getTransactionRange = (groupedData, start_date, end_date) => {
  let array = [];
  const result = eachMonthOfInterval({
    start: new Date(start_date),
    end: new Date(end_date),
  });

  let data;
  result?.forEach((element) => {
    let date = format(new Date(element), "yyyy-MM");
    data = groupedData[date] ?? [];
    array = [...array, ...data];
  });
  return array;
};

export const Highlighted = ({
  text = "",
  highlight = "",
  doNotHighlight = "",
}) => {
  const regex = highlight ? new RegExp(`(${highlight})`, "gi") : null;

  return (
    <span>
      {text.split(regex).map((part, i) => {
        if (highlight && regex.test(part)) {
          return (
            <b key={i} style={{ backgroundColor: Color.tailwind.purple[200] }}>
              {part}
            </b>
          );
        } else if (doNotHighlight && regex.test(part)) {
          return <span key={i}>{part}</span>;
        } else {
          return <>{part}</>;
        }
      })}
    </span>
  );
};

// export function removeAllCookies() {
//   var cookies = document.cookie.split("; ");
//   for (var c = 0; c < cookies.length; c++) {
//     var d = window.location.hostname.split(".");
//     while (d.length > 0) {
//       var cookieBase =
//         encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
//         "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
//         d.join(".") +
//         " ;path=";
//       var p = window.location.pathname.split("/");
//       document.cookie = cookieBase + "/";
//       while (p.length > 0) {
//         document.cookie = cookieBase + p.join("/");
//         p.pop();
//       }
//       d.shift();
//     }
//   }
// }

export function removeAllCookies() {
  var cookies = document.cookie.split("; ");
  for (var i = 0; i < cookies.length; i++) {
    var cookieName = cookies[i].split("=")[0];
    // Skip Clarity session cookie (replace 'clarity_session_id' with the actual cookie name)
    if (
      !["sessionid", "clarity_session_data", "clarity_session_id"]?.includes(
        cookieName
      )
    ) {
      var cookieBase =
        encodeURIComponent(cookieName) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/";
      document.cookie = cookieBase;
    }
  }
}

export const removeCache = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
};

export const isPlanExpired = (subscription) => {
  if (new Date() <= new Date(subscription?.expiry_date)) {
    return false;
  } else {
    return true;
  }
};

export const remToPx = (currentWidth, number) => {
  // const keys = [...theme?.breakpoints?.keys]?.reverse();
  // const currentWidth =
  //   keys?.map((output, key) => {
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     const matches = useMediaQuery(theme?.breakpoints?.up(key));
  //     return !output && matches ? key : output;
  //   }, null) || "xs";

  let px = 12;
  if (currentWidth === "s1366") {
    px = 13;
  }
  if (currentWidth === "s1536" || currentWidth === "mid") {
    px = 14;
  }
  if (currentWidth === "s1745") {
    px = 15;
  }
  if (currentWidth === "s1920") {
    px = 16;
  }

  return number * px;
};

export const isFireFox = () => {
  return typeof InstallTrigger !== "undefined";
};

export const isSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    )
  );
};

export const isOpera = () => {
  return (
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0
  );
};

export const isEdge = () => {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  return isEdge;
};

const getNumber = (categoriesById, item) => {
  let day = 0;
  if (categoriesById?.[item?.category]) {
    let term = categoriesById?.[item?.category]?.[0]?.payment_term;
    if (term) {
      day = term === "on invoicing" ? 0 : Number(term.replace("D", ""));
    }
  }
  return day;
};

export const isExpire = (item, categoriesById, recurring_rulesById) => {
  let recurring_Obj = null;
  if (item?.recurring_rule) {
    recurring_Obj =
      item?.recurring_rule && recurring_rulesById[item?.recurring_rule]
        ? recurring_rulesById[item?.recurring_rule][0]
        : null;
  }

  if (
    (item.state === "Invoice sent" || item.state === "Invoice open") &&
    (!recurring_Obj ||
      (recurring_Obj &&
        new Date(item?.due_date) <= new Date(recurring_Obj?.start_date))) &&
    ((!item.invoice_date &&
      item.due_date &&
      new Date(item.due_date) < new Date()) ||
      (item.invoice_date &&
        addDays(
          new Date(item.invoice_date),
          getNumber(categoriesById, item) ?? 0
        ) < new Date()))
  ) {
    return true;
  } else {
    return false;
  }
};

export const isOpen = (item, categoriesById) => {
  if (
    item.state === "Open" &&
    ((!item.invoice_date &&
      item.due_date &&
      new Date(item.due_date) >= new Date()) ||
      (item.invoice_date &&
        addDays(
          new Date(item.invoice_date),
          getNumber(categoriesById, item) ?? 0
        ) >= new Date()))
  ) {
    return true;
  } else {
    return false;
  }
};

export const isInvoiced = (item, categoriesById) => {
  if (
    item.state === "Invoice sent" &&
    ((!item.invoice_date &&
      item.due_date &&
      new Date(item.due_date) < new Date()) ||
      (item.invoice_date &&
        addDays(
          new Date(item.invoice_date),
          getNumber(categoriesById, item) ?? 0
        ) < new Date()))
  ) {
    return false;
  } else {
    if (item.state === "Invoice sent") {
      return true;
    } else {
      return false;
    }
  }
};

function daysInMonth(month, year) {
  // Use 1 for January, 2 for February, etc.
  return new Date(year, month, 0).getDate();
}

export const getRecurDateArray = (id, start_date, end_date) => {
  let result = [];
  let due_date = new Date(start_date);
  let data = eachMonthOfInterval({
    start: startOfMonth(due_date),
    end: startOfMonth(new Date(end_date)),
  });

  data.forEach((element, index) => {
    let day = due_date.getDate();
    const monthDay = daysInMonth(element.getMonth() + 1, element.getFullYear());
    if (monthDay < day) {
      day = monthDay;
    }
    if (index % id === 0) {
      result.push(setDate(element, day));
    }
  });
  return result;
};

export function calculatePercentageChange(value_2, value_1) {
  const change = (value_1 / (value_2 || 1)) * 100;
  return Math.abs(change?.toFixed(2));
}

export const getMonthlyValueOld = ({
  selectionCategories,
  data,
  selected_scenario_uuid,
  type = 1,
  time = "current",
  date,
  calculation_type = "amount",
  allData,
}) => {
  let currentMonth = format(new Date(), "yyyy-MM");
  let finalTotal = 0;
  let plannedTaken = false;
  let pastData = null;
  let pastTotalSum = 0;
  let pastVatSum = 0;
  let pastStartMonth = format(subMonths(new Date(), 6), "yyyy-MM");
  if (time === "current") {
    let pastTotal = 0;
    let currentTotal = 0;
    const filterData = data?.filter(
      (item) => item.month === currentMonth && !item?.category
    );

    if (allData) {
      pastData = allData?.filter(
        (item) =>
          item.month >= pastStartMonth &&
          item.month < currentMonth &&
          !item?.category &&
          !initialData.plannedState.includes(item.state) &&
          !initialData.bookedState.includes(item.state) &&
          !initialData?.calculationExcludeStates.includes(item?.state) &&
          selected_scenario_uuid?.includes(item.scenario_uuid)
      );
      pastTotal = pastData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );

      pastTotalSum = pastTotalSum + pastTotal;
    }

    let currentBookedOpen = filterData
      ?.filter((item) => !initialData.plannedState.includes(item.state))
      ?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );
    let currentPlanned = filterData
      ?.filter((item) => initialData.plannedState.includes(item.state))
      ?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );

    if (calculation_type === "amount") {
      // let isBooked = filterData.find((o1) => o1.state === "Booked");
      // if (Boolean(isBooked)) {
      //   plannedTaken = true;
      // }
      if (Math.abs(currentBookedOpen + pastTotal) >= Math.abs(currentPlanned)) {
        currentTotal = currentBookedOpen + pastTotal;
      } else {
        plannedTaken = true;
        currentTotal = currentPlanned;
      }
    } else {
      let past_vat_total = 0;
      let current_vat_total = filterData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            (
              Math.abs(currentBookedOpen + pastTotal) >=
              Math.abs(currentPlanned)
                ? !initialData.plannedState.includes(item.state)
                : initialData.plannedState.includes(item.state)
            )
              ? type === 1
                ? item?.vat_inflow ?? 0
                : item?.vat_outflow ?? 0
              : 0
          ),
        0
      );
      if (
        pastData &&
        Math.abs(currentBookedOpen + pastTotal) >= Math.abs(currentPlanned)
      ) {
        past_vat_total = pastData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(
              type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
            ),
          0
        );
        pastVatSum = pastVatSum + past_vat_total;
      }

      currentTotal = current_vat_total + past_vat_total;
    }

    finalTotal = finalTotal + currentTotal;
  }
  if (time === "future") {
    const filterData = data?.filter(
      (item) =>
        (date ? item.month > currentMonth && item.month < date : true) &&
        !item?.category
    );

    let futureOpen = filterData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !initialData.bookedState.includes(item.state) &&
            !initialData.plannedState.includes(item.state)
            ? type === 1
              ? item?.inflow ?? 0
              : item?.outflow ?? 0
            : 0
        ),
      0
    );
    let futurePlanned = filterData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          initialData.plannedState.includes(item.state)
            ? type === 1
              ? item?.inflow ?? 0
              : item?.outflow ?? 0
            : 0
        ),
      0
    );
    let currentTotal = 0;
    if (calculation_type === "amount") {
      currentTotal =
        Math.abs(futureOpen) >= Math.abs(futurePlanned)
          ? futureOpen
          : futurePlanned;
    } else {
      let current_vat_total = filterData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            (
              Math.abs(futureOpen) >= Math.abs(futurePlanned)
                ? !initialData.bookedState.includes(item.state) &&
                  !initialData.plannedState.includes(item.state)
                : initialData.plannedState.includes(item.state)
            )
              ? type === 1
                ? item?.vat_inflow ?? 0
                : item?.vat_outflow ?? 0
              : 0
          ),
        0
      );
      currentTotal = current_vat_total;
    }
    finalTotal = finalTotal + currentTotal;
  }

  selectionCategories?.forEach((category) => {
    if (category?.type === type) {
      if (time === "current") {
        let pastTotal = 0;

        const filterData = data?.filter(
          (item) =>
            item.month === currentMonth && item?.category === category?.uuid
        );
        if (allData) {
          pastData = allData?.filter(
            (item) =>
              item.month >= pastStartMonth &&
              item.month < currentMonth &&
              !initialData.bookedState.includes(item.state) &&
              !initialData.plannedState.includes(item.state) &&
              !initialData?.calculationExcludeStates.includes(item?.state) &&
              selected_scenario_uuid?.includes(item.scenario_uuid)
          );

          pastTotal = pastData
            ?.filter((item) => item?.category === category?.uuid)
            ?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
              0
            );
          pastTotalSum = pastTotalSum + pastTotal;
        }
        let currentBookedOpen = filterData
          ?.filter((item) => !initialData.plannedState.includes(item.state))
          ?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );
        let currentPlanned = filterData
          ?.filter((item) => initialData.plannedState.includes(item.state))
          ?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );

        let currentTotal = 0;

        if (calculation_type === "amount") {
          // let isBooked = filterData.find((o1) => o1.state === "Booked");
          // if (Boolean(isBooked)) {
          //   plannedTaken = true;
          // }
          if (
            Math.abs(currentBookedOpen + pastTotal) >= Math.abs(currentPlanned)
          ) {
            currentTotal = currentBookedOpen + pastTotal;
          } else {
            plannedTaken = true;
            currentTotal = currentPlanned;
          }
        } else {
          let past_vat_total = 0;
          let current_vat_total = filterData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(
                (
                  Math.abs(currentBookedOpen + pastTotal) >=
                  Math.abs(currentPlanned)
                    ? !initialData.plannedState.includes(item.state)
                    : initialData.plannedState.includes(item.state)
                )
                  ? type === 1
                    ? item?.vat_inflow ?? 0
                    : item?.vat_outflow ?? 0
                  : 0
              ),
            0
          );
          if (
            pastData &&
            Math.abs(currentBookedOpen + pastTotal) >= Math.abs(currentPlanned)
          ) {
            past_vat_total = pastData
              ?.filter((item) => item?.category === category?.uuid)
              ?.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(
                    type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                  ),
                0
              );
            pastVatSum = pastVatSum + past_vat_total;
          }

          currentTotal = current_vat_total + past_vat_total;
        }

        finalTotal = finalTotal + currentTotal;
      }
      if (time === "future") {
        const filterData = data?.filter(
          (item) =>
            (date ? item.month > currentMonth && item.month < date : true) &&
            item?.category === category?.uuid
        );
        let futureOpen = filterData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(
              !initialData.bookedState.includes(item.state) &&
                !initialData.plannedState.includes(item.state)
                ? type === 1
                  ? item?.inflow ?? 0
                  : item?.outflow ?? 0
                : 0
            ),
          0
        );
        let futurePlanned = filterData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(
              initialData.plannedState.includes(item.state)
                ? type === 1
                  ? item?.inflow ?? 0
                  : item?.outflow ?? 0
                : 0
            ),
          0
        );
        let currentTotal = 0;

        if (calculation_type === "amount") {
          currentTotal =
            Math.abs(futureOpen) >= Math.abs(futurePlanned)
              ? futureOpen
              : futurePlanned;
        } else {
          let current_vat_total = filterData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(
                (
                  Math.abs(futureOpen) >= Math.abs(futurePlanned)
                    ? !initialData.bookedState.includes(item.state) &&
                      !initialData.plannedState.includes(item.state)
                    : initialData.plannedState.includes(item.state)
                )
                  ? type === 1
                    ? item?.vat_inflow ?? 0
                    : item?.vat_outflow ?? 0
                  : 0
              ),
            0
          );
          currentTotal = current_vat_total;
        }
        finalTotal = finalTotal + currentTotal;
      }
    }
  });

  return {
    total: finalTotal,
    plannedTaken,
    past: { total: pastTotalSum, vat: pastVatSum },
  };
};

export const getStartingLiquidityValueOld = ({
  monthlyTransactions,
  selected_scenario_uuid,
  date,
  selectionCategories,
  inflow_key = "inflow",
  outflow_key = "outflow",
  calculation_type = "amount",
  advanceVat,
  vatDueDate,
  vat_pay_months = 0,
  groupedMonthlyTransactions,
}) => {
  let LiquidityTotal = 0;
  let isPlannedTaken = false;
  let currentMonth = format(new Date(), "yyyy-MM");
  if (calculation_type === "Vat") {
    let vatCurrentMonth = format(
      subMonths(new Date(), vat_pay_months),
      "yyyy-MM"
    );
    let currentDay = format(new Date(), "yyyy-MM-dd");

    if (date && vat_pay_months) {
      let start_vat_date = subMonths(new Date(date), 6);
      let start_vat_calculation_date = subMonths(new Date(date), 1);

      const result = eachMonthOfInterval({
        start: start_vat_date,
        end: start_vat_calculation_date,
      });
      const filterData = monthlyTransactions?.filter(
        (item) =>
          !initialData?.calculationExcludeStates2.includes(item?.state) &&
          selected_scenario_uuid?.includes(item.scenario_uuid)
      );
      let run_future_vat_once = false;
      result?.forEach((vat_date) => {
        let formatted_date = format(vat_date, "yyyy-MM");

        let subtractVat =
          advanceVat?.enabled &&
          (formatted_date > vatCurrentMonth ||
            (formatted_date === vatCurrentMonth && currentDay <= vatDueDate));

        const isPastMonth = formatted_date < currentMonth;
        const isCurrentMonth = formatted_date === currentMonth;
        const isFutureMonth = formatted_date > currentMonth;
        const statsData = groupedMonthlyTransactions[formatted_date] ?? [];

        let total = 0;

        if (subtractVat) {
          if (isPastMonth) {
            const finalData = statsData?.filter(
              (item) =>
                !initialData?.calculationExcludeStates2.includes(item?.state) &&
                selected_scenario_uuid?.includes(item.scenario_uuid) &&
                item.state === "Booked"
            );
            total = finalData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(item?.[inflow_key] ?? 0) +
                parseFloat(item?.[outflow_key] ?? 0),
              0
            );
          }
          if (isCurrentMonth) {
            let currentInflowTotal = getMonthlyValueOld({
              selectionCategories,
              selected_scenario_uuid,
              data: filterData,
              type: 1,
              time: "current",
              calculation_type: "Vat",
              allData: filterData,
            });

            let currentOutflowTotal = getMonthlyValueOld({
              selectionCategories,
              selected_scenario_uuid,
              data: filterData,
              type: 2,
              time: "current",
              calculation_type: "Vat",
              allData: filterData,
            });

            total =
              (currentInflowTotal?.total || 0) +
              (currentOutflowTotal?.total || 0);
          }
          if (isFutureMonth && !run_future_vat_once) {
            run_future_vat_once = true;
            let futureInflowTotal = getMonthlyValueOld({
              selectionCategories,
              selected_scenario_uuid,
              data: filterData,
              type: 1,
              time: "future",
              date,
              calculation_type: "Vat",
            });

            let futureOutflowTotal = getMonthlyValueOld({
              selectionCategories,
              selected_scenario_uuid,
              data: filterData,
              type: 2,
              time: "future",
              date,
              calculation_type: "Vat",
            });

            total =
              (futureInflowTotal?.total || 0) +
              (futureOutflowTotal?.total || 0);
          }
        }

        LiquidityTotal = LiquidityTotal + total;
      });
    }
  } else {
    if (date > currentMonth) {
      let currentTotal = 0;
      let futureTotal = 0;
      const filterData = monthlyTransactions?.filter(
        (item) =>
          !initialData?.calculationExcludeStates2.includes(item?.state) &&
          selected_scenario_uuid?.includes(item.scenario_uuid)
      );

      let allData = monthlyTransactions?.filter(
        (item) =>
          !initialData.bookedState.includes(item.state) &&
          !initialData.plannedState.includes(item.state) &&
          !initialData?.calculationExcludeStates.includes(item?.state)
      );

      let currentData = filterData?.filter((o1) => o1.month === currentMonth);

      let currentInflowTotal = getMonthlyValueOld({
        selectionCategories,
        selected_scenario_uuid,
        data: currentData,
        type: 1,
        time: "current",
        allData,
      });

      let currentOutflowTotal = getMonthlyValueOld({
        selectionCategories,
        selected_scenario_uuid,
        data: currentData,
        type: 2,
        time: "current",
        allData,
      });

      if (
        currentInflowTotal?.plannedTaken ||
        currentOutflowTotal?.plannedTaken
      ) {
        isPlannedTaken = true;
      }
      currentTotal =
        (currentInflowTotal?.total || 0) + (currentOutflowTotal?.total || 0);

      let futureDates = eachMonthOfInterval({
        start: new Date(),
        end: new Date(date),
      });

      futureDates?.forEach((element) => {
        let formatted_date = format(new Date(element), "yyyy-MM");
        if (formatted_date > currentMonth && formatted_date < date) {
          const statsData = groupedMonthlyTransactions?.[formatted_date] ?? [];
          let futureData = statsData?.filter(
            (item) =>
              !initialData?.calculationExcludeStates.includes(item?.state) &&
              selected_scenario_uuid?.includes(item.scenario_uuid)
          );
          let futureInflowTotal = getMonthlyValueOld({
            selectionCategories,
            selected_scenario_uuid,
            data: futureData,
            type: 1,
            time: "future",
          });
          let futureOutflowTotal = getMonthlyValueOld({
            selectionCategories,
            selected_scenario_uuid,
            data: futureData,
            type: 2,
            time: "future",
          });

          futureTotal =
            futureTotal +
            (futureInflowTotal?.total || 0) +
            (futureOutflowTotal?.total || 0);
          // console.log(
          //   "🚀 // futureData:",
          //   formatted_date,
          //   futureData,
          //   futureInflowTotal?.total || 0,
          //   futureOutflowTotal?.total || 0,
          //   futureTotal
          // );
        }
      });

      LiquidityTotal = currentTotal + futureTotal;
    }
  }
  return { LiquidityTotal, isPlannedTaken };
};

export function levenshteinDistance(str1, str2) {
  const len1 = str1.length;
  const len2 = str2.length;
  const matrix = [];

  for (let i = 0; i <= len2; i++) {
    matrix[i] = [i];
  }

  for (let i = 0; i <= len1; i++) {
    matrix[0][i] = i;
  }

  for (let i = 1; i <= len2; i++) {
    for (let j = 1; j <= len1; j++) {
      const cost = str1[j - 1] === str2[i - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // Deletion
        matrix[i][j - 1] + 1, // Insertion
        matrix[i - 1][j - 1] + cost // Substitution
      );
    }
  }

  return matrix[len2][len1];
}

export function calculateSimilarity(item1, item2, type = "list") {
  let similarity = 0;
  let title_similarity = 0;
  let value_similarity = 0;
  let date_similarity = 0;
  const distance = levenshteinDistance(item1?.title, item2?.title);

  // Calculate similarity score (1 - normalized distance)
  title_similarity =
    1 - distance / Math.max(item1?.title?.length, item2?.title?.length);

  // Compare "amount" similarity
  const maxAmountDifference = type === "list" ? 1 : 2; // Assume maximum amount difference is 1 or 2
  const amountDifference = Math.abs(item1.gross_value - item2.gross_value);
  value_similarity = 1 - amountDifference / maxAmountDifference;
  similarity += value_similarity;

  // Compare "date" similarity
  const dateDifference = differenceInCalendarDays(
    new Date(item1.due_date),
    new Date(item2.due_date)
  );
  const maxDateDifference = type === "list" ? 60 : 90; // Assume maximum acceptable difference is 2 or 3 months
  date_similarity = 1 - Math.abs(dateDifference) / maxDateDifference;
  similarity += date_similarity;
  return {
    similarity: title_similarity + similarity,
    title_similarity,
    value_similarity,
    date_similarity,
  };
}

//api................................................................................................
const pageBatchNumber = 200;

export const buildUrlFromParams = (params) => {
  const queryParams = Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  const url = `?${queryParams}`;
  return url;
};

export const getTransactionByParams = async (params) => {
  let url = `?missing_date=false`;
  if (params?.dataset) {
    url = url + `&dataset=${params?.dataset}`;
  }
  if (params?.category_dataset) {
    url = url + `&category_dataset=${params?.category_dataset}`;
  }
  if (params?.global_category) {
    url = url + `&global_category=${params?.global_category}`;
  }
  if (params?.title?.length > 0) {
    url = url + `&title=${params?.title}`;
  }
  if (params?.title_or_note) {
    url = url + `&title_or_note=${params?.title_or_note}`;
  }
  if (params?.date) {
    let start_date = format(startOfMonth(new Date(params?.date)), "yyyy-MM-dd");
    let end_date = format(endOfMonth(new Date(params?.date)), "yyyy-MM-dd");
    url = url + `&from_date=${start_date}&to_date=${end_date}`;
  }
  if (params?.startDate) {
    let start_date = format(new Date(params?.startDate), "yyyy-MM-dd");
    url = url + `&from_date=${start_date}`;
  }
  if (params?.endDate) {
    let end_date = format(new Date(params?.endDate), "yyyy-MM-dd");
    url = url + `&to_date=${end_date}`;
  }
  if (params?.from_payment_date) {
    let from_payment_date = format(
      new Date(params?.from_payment_date),
      "yyyy-MM-dd"
    );
    url = url + `&from_payment_date=${from_payment_date}`;
  }
  if (params?.to_payment_date) {
    let to_payment_date = format(
      new Date(params?.to_payment_date),
      "yyyy-MM-dd"
    );
    url = url + `&to_payment_date=${to_payment_date}`;
  }
  if (params?.order_by) {
    url = url + `&order_by=${params?.order_by}`;
  }
  if (params?.bank_transaction_type) {
    url = url + `&bank_transaction_type=${params?.bank_transaction_type}`;
  }
  if (params?.source) {
    url = url + `&source=${params?.source}`;
  }
  if (params?.hasOwnProperty("categorized")) {
    url = url + `&categorized=${params?.categorized}`;
  }
  if (params?.hasOwnProperty("is_recurred")) {
    url = url + `&is_recurred=${params?.is_recurred}`;
  }
  if (params?.hasOwnProperty("detailed")) {
    url = url + `&detailed=${params?.detailed}`;
  }
  if (params?.hasOwnProperty("is_reconciled")) {
    url = url + `&is_reconciled=${params?.is_reconciled}`;
  }
  if (params?.hasOwnProperty("reconciled")) {
    url = url + `&reconciled=${params?.reconciled}`;
  }
  if (params?.hasOwnProperty("min_gross_value")) {
    url = url + `&min_gross_value=${params?.min_gross_value}`;
  }
  if (params?.hasOwnProperty("max_gross_value")) {
    url = url + `&max_gross_value=${params?.max_gross_value}`;
  }
  if (params?.similar_title) {
    url = url + `&similar_title=${params?.similar_title}`;
  }
  if (params?.gross_value) {
    url = url + `&gross_value=${params?.gross_value}`;
  }
  if (params?.data_source?.length > 0) {
    params?.data_source?.forEach((element) => {
      url = url + "&data_source=" + element;
    });
  }
  if (params?.category?.length > 0) {
    params?.category?.forEach((element) => {
      url = url + "&category=" + element;
    });
  }
  if (params?.state?.length > 0) {
    params?.state?.forEach((element) => {
      url = url + "&state=" + element;
    });
  }
  if (params?.scenario?.length > 0) {
    params?.scenario?.forEach((element) => {
      url = url + "&scenario=" + element;
    });
  }
  if (params?.recurring_rule?.length > 0) {
    params?.recurring_rule?.forEach((element) => {
      url = url + "&recurring_rule=" + element;
    });
  }
  if (params?.cost_unit?.length > 0) {
    params?.cost_unit?.forEach((element) => {
      url = url + "&cost_unit=" + element;
    });
  }
  if (params?.contact?.length > 0) {
    params?.contact?.forEach((element) => {
      url = url + "&contact=" + element;
    });
  }
  if (params?.page) {
    url = url + `&page=${params?.page}`;
  }
  if (params?.page_size) {
    url = url + `&page_size=${params?.page_size}`;
  }
  if (params?.url) {
    url = url + params?.url;
  }
  if (params?.emptyState || params?.emptyScenario) {
    return [];
  }
  let data = null;
  await APICall(
    "get",
    params?.endpoint
      ? EndPoints[params?.endpoint] + url
      : EndPoints.transactions + url,
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      data = response.data;
    }
  });
  return data;
};

export const getAllTransactionsByParams = async (params) => {
  let data = [];
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  const LIMIT = pLimit(5);

  const getURL = () => {
    let url = `?missing_date=false`;
    if (params?.dataset) {
      url = url + `&dataset=${params?.dataset}`;
    }
    if (params?.category_dataset) {
      url = url + `&category_dataset=${params?.category_dataset}`;
    }
    if (params?.global_category) {
      url = url + `&global_category=${params?.global_category}`;
    }
    if (params?.title?.length > 0) {
      url = url + `&title=${params?.title}`;
    }
    if (params?.date) {
      let start_date = format(
        startOfMonth(new Date(params?.date)),
        "yyyy-MM-dd"
      );
      let end_date = format(endOfMonth(new Date(params?.date)), "yyyy-MM-dd");
      url = url + `&from_date=${start_date}&to_date=${end_date}`;
    }
    if (params?.startDate) {
      let start_date = format(new Date(params?.startDate), "yyyy-MM-dd");
      url = url + `&from_date=${start_date}`;
    }
    if (params?.endDate) {
      let end_date = format(new Date(params?.endDate), "yyyy-MM-dd");
      url = url + `&to_date=${end_date}`;
    }
    if (params?.from_payment_date) {
      let from_payment_date = format(
        new Date(params?.from_payment_date),
        "yyyy-MM-dd"
      );
      url = url + `&from_date=${from_payment_date}`;
    }
    if (params?.to_payment_date) {
      let to_payment_date = format(
        new Date(params?.to_payment_date),
        "yyyy-MM-dd"
      );
      url = url + `&to_payment_date=${to_payment_date}`;
    }
    if (params?.order_by) {
      url = url + `&order_by=${params?.order_by}`;
    }
    if (params?.bank_transaction_type) {
      url = url + `&bank_transaction_type=${params?.bank_transaction_type}`;
    }
    if (params?.hasOwnProperty("categorized")) {
      url = url + `&categorized=${params?.categorized}`;
    }
    if (params?.hasOwnProperty("is_recurred")) {
      url = url + `&is_recurred=${params?.is_recurred}`;
    }
    if (params?.hasOwnProperty("detailed")) {
      url = url + `&detailed=${params?.detailed}`;
    }
    if (params?.hasOwnProperty("is_reconciled")) {
      url = url + `&is_reconciled=${params?.is_reconciled}`;
    }
    if (params?.hasOwnProperty("reconciled")) {
      url = url + `&reconciled=${params?.reconciled}`;
    }
    if (params?.hasOwnProperty("min_gross_value")) {
      url = url + `&min_gross_value=${params?.min_gross_value}`;
    }
    if (params?.hasOwnProperty("max_gross_value")) {
      url = url + `&max_gross_value=${params?.max_gross_value}`;
    }
    if (params?.source) {
      url = url + `&source=${params?.source}`;
    }
    if (params?.similar_title) {
      url = url + `&similar_title=${params?.similar_title}`;
    }
    if (params?.gross_value) {
      url = url + `&gross_value=${params?.gross_value}`;
    }
    if (params?.data_source?.length > 0) {
      params?.data_source?.forEach((element) => {
        url = url + "&data_source=" + element;
      });
    }
    if (params?.category?.length > 0) {
      params?.category?.forEach((element) => {
        url = url + "&category=" + element;
      });
    }
    if (params?.state?.length > 0) {
      params?.state?.forEach((element) => {
        url = url + "&state=" + element;
      });
    }
    if (params?.recurring_rule?.length > 0) {
      params?.recurring_rule?.forEach((element) => {
        url = url + "&recurring_rule=" + element;
      });
    }
    if (params?.scenario?.length > 0) {
      params?.scenario?.forEach((element) => {
        url = url + "&scenario=" + element;
      });
    }
    if (params?.cost_unit?.length > 0) {
      params?.cost_unit?.forEach((element) => {
        url = url + "&cost_unit=" + element;
      });
    }
    if (params?.contact?.length > 0) {
      params?.contact?.forEach((element) => {
        url = url + "&contact=" + element;
      });
    }
    if (params?.page) {
      page = params?.page;
      url = url + `&page=${params?.page}`;
    } else {
      url = url + `&page=${page}`;
    }
    if (params?.page_size) {
      pageSize = params?.page_size;
      url = url + `&page_size=${params?.page_size}`;
    } else {
      pageSize = pageBatchNumber;
      url = url + `&page_size=${pageBatchNumber}`;
    }
    if (params?.url) {
      url = url + params?.url;
    }
    return url;
  };
  await APICall(
    "get",
    params?.endpoint
      ? EndPoints[params?.endpoint] + getURL()
      : EndPoints.transactions + getURL(),
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      data = response.data.results;
    }
  });
  if (count > data?.length) {
    let number = Math.ceil(count / pageSize) - 1;
    let promises = Array.from(new Array(number)).map(() => {
      page++;

      let url = getURL();
      return LIMIT(() =>
        APICall(
          "get",
          params?.endpoint
            ? EndPoints[params?.endpoint] + url
            : EndPoints.transactions + url,
          null,
          params?.config
        )
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
  }

  return data;
};

export const getAllRules = async (params) => {
  let data = [];
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  const LIMIT = pLimit(5);

  const getURL = () => {
    return `?page=${page}`;
  };
  const url = getURL();

  await APICall(
    "get",
    EndPoints.transactions_rules + url,
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      data = response.data.results;
    }
  });
  if (count > data?.length) {
    const number = Math.ceil(count / pageSize) - 1;
    const promises = Array.from(new Array(number)).map(() => {
      page++;

      const url = getURL();
      return LIMIT(() =>
        APICall("get", EndPoints.transactions_rules + url, null, params?.config)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
  }

  return data;
};

export const getTransactionById = async (uuid) => {
  let data = null;
  await APICall("get", EndPoints.transactions + `${uuid}/`).then((response) => {
    if (response.status === 200 && response.data) {
      data = response.data;
    }
  });
  return data;
};

export const getStatisticsData = async (
  datasetID,
  type = "monthly",
  startDate,
  endDate,
  subMonthNumber = 5,
  filter = "",
  params
) => {
  const LIMIT = pLimit(5);
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  let data = [];
  let booked_balances = [];
  let start_date = "";
  let end_date = "";
  let last_modification_date = null;
  // let c0 = performance.now();
  const getURL = () => {
    let url = `?dataset=${datasetID}&page=${page}&page_size=${pageSize}`;

    if (startDate) {
      start_date = format(
        subMonths(new Date(startDate), subMonthNumber),
        "yyyy-MM-dd"
      );
      url = url + `&from_date=${start_date}`;
    }
    if (endDate) {
      end_date = format(new Date(endDate), "yyyy-MM-dd");
      url = url + `&to_date=${end_date}`;
    }
    if (type === "monthly") {
      url = EndPoints.transaction_monthly_statistic + url + filter;
    }
    if (type === "daily") {
      url = EndPoints.transaction_daily_statistic + url + filter;
    }
    if (type === "payment_monthly") {
      url = EndPoints.transaction_payment_monthly_statistic + url + filter;
    }
    if (type === "payment_daily") {
      url = EndPoints.transaction_payment_daily_statistic + url + filter;
    }
    if (type === "transaction_monthly_chart") {
      url = EndPoints.transaction_monthly_chart + url + filter;
    }
    if (type === "transaction_daily_chart") {
      url = EndPoints.transaction_daily_chart + url + filter;
    }
    return url;
  };
  await APICall("get", getURL(), null, params?.config).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      let resData = response.data.results;
      last_modification_date = response.data.last_modification_date;

      if (response.data?.current) {
        data = [...resData, response.data?.current];
      } else {
        data = resData;
      }

      booked_balances = response.data?.booked_balances;
    }
  });
  if (count > data.length) {
    let number = Math.ceil(count / pageSize) - 1;
    let promises = Array.from(new Array(number)).map(() => {
      page++;
      let url = getURL();
      return LIMIT(() => APICall("get", url, null, params?.config));
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          if (response?.value?.data?.current) {
            data = [...data, ...results, response?.value?.data?.current];
          } else {
            data = [...data, ...results];
          }

          if (response?.value?.data?.booked_balances) {
            booked_balances = [
              ...booked_balances,
              ...response?.value?.data?.booked_balances,
            ];
          }
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
  }
  // let c1 = performance.now();
  // console.log(
  //   `<<--Fetching ${type ? type : "daily"} statistics took --> ${c1 - c0} ms.`
  // );
  return {
    results: data,
    booked_balances: booked_balances,
    count: count,
    last_modification_date: last_modification_date,
  };
};

export const getStatisticsDataWithParams = async (params) => {
  const LIMIT = pLimit(5);
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  let data = [];
  let booked_balances = [];
  let start_date = "";
  let end_date = "";
  let last_modification_date = null;
  // let c0 = performance.now();
  const getURL = () => {
    let url = `?missing_date=false&page=${page}&page_size=${pageSize}`;
    if (params?.category_dataset) {
      url = url + `&category_dataset=${params?.category_dataset}`;
    }
    if (params?.global_category) {
      url = url + `&global_category=${params?.global_category}`;
    }
    if (params?.dataset) {
      url = url + `&dataset=${params?.dataset}`;
    }
    if (params?.group_by) {
      params?.group_by?.forEach((id) => {
        url = url + `&group_by=${id}`;
      });
    }
    if (params?.from_date) {
      start_date = format(
        subMonths(new Date(params?.from_date), params?.subMonthNumber || 0),
        "yyyy-MM-dd"
      );
      url = url + `&from_date=${start_date}`;
    }
    if (params?.to_date) {
      end_date = format(new Date(params?.to_date), "yyyy-MM-dd");
      url = url + `&to_date=${end_date}`;
    }
    if (params?.from_payment_date) {
      start_date = format(
        subMonths(
          new Date(params?.from_payment_date),
          params?.subMonthNumber || 0
        ),
        "yyyy-MM-dd"
      );
      url = url + `&from_payment_date=${start_date}`;
    }
    if (params?.to_payment_date) {
      end_date = format(new Date(params?.to_payment_date), "yyyy-MM-dd");
      url = url + `&to_payment_date=${end_date}`;
    }
    if (params?.multiScenarioIds) {
      params?.multiScenarioIds?.forEach((id) => {
        url = url + `&scenario=${id}`;
      });
    }
    if (params?.multiCategoryIds) {
      const isUncategorized = params?.multiCategoryIds?.includes(
        "unCategorized_category"
      );
      const selectionCategoriesByID =
        store?.getState()?.categorySlice?.selectionCategoriesByID;
      params?.multiCategoryIds?.forEach((id) => {
        const _category = selectionCategoriesByID?.[id]?.[0]?.uuid;
        if (_category) {
          if (isUncategorized) {
            url = url + `&uncategorized_or_with_category=${id}`;
          } else {
            url = url + `&category=${id}`;
          }
        }
      });
    }
    if (params?.multiStatesIds) {
      params?.multiStatesIds?.forEach((id) => {
        url = url + `&state=${id}`;
      });
    }
    if (params?.filter) {
      url = url + params?.filter;
    }
    if (params?.type === "monthly") {
      url = EndPoints.transaction_monthly_statistic + url;
    }
    if (params?.type === "daily") {
      url = EndPoints.transaction_daily_statistic + url;
    }
    if (params?.type === "payment_monthly") {
      url = EndPoints.transaction_payment_monthly_statistic + url;
    }
    if (params?.type === "payment_daily") {
      url = EndPoints.transaction_payment_daily_statistic + url;
    }
    if (params?.type === "transaction_monthly_chart") {
      url = EndPoints.transaction_monthly_chart + url;
    }
    if (params?.type === "transaction_daily_chart") {
      url = EndPoints.transaction_daily_chart + url;
    }
    return url;
  };
  await APICall("get", getURL(), null, params?.config).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      let resData = response.data.results;
      last_modification_date = response.data.last_modification_date;

      if (response.data?.current) {
        data = [...resData, response.data?.current];
      } else {
        data = resData;
      }

      booked_balances = response.data?.booked_balances;
    }
  });
  if (count > data.length) {
    let number = Math.ceil(count / pageSize) - 1;
    let promises = Array.from(new Array(number)).map(() => {
      page++;
      let url = getURL();
      return LIMIT(() => APICall("get", url, null, params?.config));
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          if (response?.value?.data?.current) {
            data = [...data, ...results, response?.value?.data?.current];
          } else {
            data = [...data, ...results];
          }

          if (response?.value?.data?.booked_balances) {
            booked_balances = [
              ...booked_balances,
              ...response?.value?.data?.booked_balances,
            ];
          }
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
  }
  // let c1 = performance.now();
  // console.log(
  //   `<<--Fetching ${type ? type : "daily"} statistics took --> ${c1 - c0} ms.`
  // );
  return {
    results: data,
    booked_balances: booked_balances,
    count: count,
    last_modification_date: last_modification_date,
  };
};

export const getRecurringTransactions = async (
  datasetID = null,
  recurringRules = [],
  startDate = null,
  endDate = null,
  endurl = "",
  params = {}
) => {
  const LIMIT = pLimit(5);
  let pageSize = 1;
  let count = 0;
  let page = 1;
  let data = [];
  const getURL = () => {
    let url = `?missing_date=unknown&is_reconciled=false&page=${page}&page_size=${pageSize}${endurl}`;
    if (datasetID) {
      url = `?dataset=${datasetID}&page=${page}&page_size=${pageSize}&missing_date=unknown&is_reconciled=false&order_by=-due_date${endurl}`; //&source=1
    }

    if (startDate) {
      let start_date = format(new Date(startDate), "yyyy-MM-dd");
      url = url + `&from_date=${start_date}`;
    }
    if (endDate) {
      let end_date = format(new Date(endDate), "yyyy-MM-dd");
      url = url + `&to_date=${end_date}`;
    }
    if (recurringRules?.length > 0) {
      recurringRules?.forEach((element) => {
        url = url + "&recurring_rule=" + element;
      });
    }
    return url;
  };
  await APICall(
    "get",
    EndPoints.transactions + getURL(),
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      let resData = response.data.results;
      if (response.data.next) {
        // page++;
        pageSize = pageBatchNumber;
      } else {
        data = [...data, ...resData];
        // return data;
      }
    }
  });
  if (pageSize === 1) {
    return data;
  }
  if (count > data.length) {
    let number = Math.ceil(count / pageSize);
    let promises = Array.from(new Array(number)).map(() => {
      let url = getURL();
      page++;
      return LIMIT(() =>
        APICall("get", EndPoints.transactions + url, null, params?.config)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const getTransactionByUrl = async (
  datasetID,
  endURL = "",
  params = {}
) => {
  const LIMIT = pLimit(5);
  let pageSize = 1;
  let count = 0;
  let page = 1;
  let data = [];

  const getURL = () => {
    let url;
    if (datasetID) {
      url = `?dataset=${datasetID}&page=${page}&page_size=${pageSize}&is_reconciled=false${endURL}&order_by=-due_date`;
    } else {
      url = `?page=${page}&page_size=${pageSize}&is_reconciled=false${endURL}&order_by=-due_date`;
    }
    return url;
  };
  await APICall(
    "get",
    EndPoints.transactions + getURL(),
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      let resData = response.data.results;
      if (response.data.next) {
        // page++;
        pageSize = pageBatchNumber;
      } else {
        data = [...data, ...resData];
        // return data;
      }
    }
  });
  if (pageSize === 1) {
    return data;
  }
  if (count > data.length) {
    let number = Math.ceil(count / pageSize);
    let promises = Array.from(new Array(number)).map(() => {
      let url = getURL();
      page++;
      return LIMIT(() =>
        APICall("get", EndPoints.transactions + url, null, params?.config)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const fetchConnectedBookedBalances = async ({
  array,
  type = "monthly",
  params,
}) => {
  const LIMIT = pLimit(5);
  let pageSize = pageBatchNumber;
  let page = 1;
  let count = 0;
  let tcount = 0;
  let data = [];
  let urls = [];
  let last_modification_date = true;

  const getURL = (element) => {
    let url = `${EndPoints.accounts}${element?.accountID}/balances/${element?.balanceID}/values/${type ? type + "/" : ""}?page=${page}&page_size=${pageSize}&is_reconciled=false`;

    if (params?.from_date) {
      url = url + `&from_date=${params?.from_date}`;
    }
    if (params?.to_date) {
      url = url + `&to_date=${params?.to_date}`;
    }
    return url;
  };

  while (array?.length > 0) {
    let arrayData = array.splice(0, 1);
    let element = arrayData[0];

    await APICall("get", getURL(element), null, params?.config).then(
      // eslint-disable-next-line no-loop-func
      (response) => {
        if (response.status === 200 && response.data) {
          count = Number(response.data.count);
          tcount = tcount + Number(response.data.count);
          last_modification_date = response.data.last_modified_date;
          data = {
            ...data,
            [element.accountID]: response.data.results,
          };
          if (response.data.next) {
            let number = Math.ceil(count / pageSize) - 1;
            Array.from(new Array(number)).forEach(() => {
              page++;
              let url = getURL(element);
              urls.push(LIMIT(() => APICall("get", url, null, params?.config)));
            });
          }
        }
      }
    );
    if (tcount > data[element?.accountID]?.length) {
      try {
        const responses = await Promise.allSettled(urls);
        for (const response of responses) {
          if (response?.status === "fulfilled") {
            let results = response?.value?.data?.results;
            if (results) {
              data = {
                ...data,
                [element?.accountID]: [
                  ...(data[element?.accountID] || []),
                  ...results,
                ],
              };
            }
          }
        }
      } catch (err) {
        console.log(`error:allSettled `, err);
      }
    }
  }
  let mergedArray = [];

  for (const key in data) {
    data[key].forEach((item) => {
      const existingEntry = mergedArray.find(
        (entry) => entry.month === item.month
      );
      if (existingEntry) {
        existingEntry[key] = item.value;
      } else {
        mergedArray.push({ month: item.month, [key]: item.value });
      }
    });
  }

  return { data: mergedArray, last_modification_date };
};

export const fetchConnectedManualBalances = async (
  datasetID,
  array,
  type = "monthly"
) => {
  // let c0 = performance.now();
  let data = [];
  let allow = true;
  let last_modification_date = true;
  if (array?.length > 0) {
    while (allow) {
      let arrayData = array.splice(0, 1);
      let element = arrayData[0];
      await APICall(
        "get",
        EndPoints.accounts +
          `${element?.accountID}/balances/${element?.balanceID}/values${
            type ? "/" + type : ""
          }/`,
        null,
        true
        // eslint-disable-next-line no-loop-func
      ).then((response) => {
        if (response.status === 200 && response.data) {
          let results = response.data.results;
          let dummyData = [];
          if (results?.length > 0) {
            if (type === "monthly") {
              let start = new Date(response.data?.results[0]?.month);
              let end = new Date();
              if (start > end) {
                let dummy = start;
                start = end;
                end = dummy;
              }
              const monthResult = eachMonthOfInterval({
                start: start,
                end: end,
              });
              monthResult.forEach((element) => {
                dummyData.push({
                  month: format(element, "yyyy-MM"),
                  value: response.data.results[0]?.value,
                });
              });
            } else {
              let start = new Date(response.data?.results[0]?.date);
              let end = new Date();
              if (start > end) {
                let dummy = start;
                start = end;
                end = dummy;
              }
              const dateResult = eachDayOfInterval({
                start: start,
                end: end,
              });
              dateResult.forEach((element) => {
                dummyData.push({
                  date: format(element, "yyyy-MM-dd"),
                  value: response.data.results[0]?.value,
                });
              });
            }
          }
          data = [...data, ...dummyData];

          last_modification_date = response.data.last_modification_date;
          if (array?.length > 0) {
            allow = true;
          } else {
            allow = false;
          }
        }
      });
    }
  }
  // let c1 = performance.now();
  // console.log(
  //   `<<--Fetching ${type ? type : "daily"} Manual Balances took --> ${
  //     c1 - c0
  //   } ms.`
  // );
  return { data, last_modification_date };
};

export const cloneBatchTransactions = async (array, no = 50) => {
  let data = [];
  const LIMIT = pLimit(5);
  let count = array?.length;
  if (count > data.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("post", EndPoints.transactions + "batch_clone/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const addBatchTransactions = async (array, no = 50) => {
  let data = [];
  const LIMIT = pLimit(5);
  let count = array?.length;
  if (count > data.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("post", EndPoints.transactions + "batch_add/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const deleteBatchTransactions = async (uuids, no = 50) => {
  let isDeleted = false;
  // const LIMIT = pLimit(5);
  // let count = uuids?.length;
  // let number = Math.ceil(count / no);
  // let promises = Array.from(new Array(number)).map(() => {
  //   let arrayData = uuids.splice(0, no);
  //   return LIMIT(() =>
  //     APICall("post", EndPoints.transactions + "batch_delete/", arrayData)
  //   );
  // });
  // try {
  //   const responses = await Promise.allSettled(promises);
  //   for (const response of responses) {
  //     if (response?.status === "fulfilled") {
  //       isDeleted = true;
  //     }
  //   }
  // } catch (err) {
  //   isDeleted = false;
  //   console.log(`error:allSettled `, err);
  // }

  await APICall("post", EndPoints.transactions + "batch_delete/", uuids).then(
    (response) => {
      if (response.status === 204) {
        isDeleted = true;
      } else {
        isDeleted = false;
      }
    }
  );
  return isDeleted;
};

export const updateBatchTransactions = async (_array, no = 50) => {
  const LIMIT = pLimit(5);
  let data = [];
  let array = [..._array];
  let count = array?.length;
  if (count > data?.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number))?.map(() => {
      let arrayData = array?.splice(0, no);
      return LIMIT(() =>
        APICall("put", EndPoints.transactionsBatch, arrayData)
      );
    });

    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }

  // let allow = true;
  // if (type === "all") {
  //   while (allow) {
  //     let arrayData = array.splice(0, number);
  //     await APICall(
  //       "put",
  //       EndPoints.transactionsBatch,
  //       arrayData
  //       // eslint-disable-next-line no-loop-func
  //     ).then((response) => {
  //       if (response.status === 200 && response.data) {
  //         data = [...data, ...response.data];
  //         if (array?.length >= number || array?.length > 0) {
  //           allow = true;
  //         } else {
  //           allow = false;
  //         }
  //       }
  //     });
  //   }
  // } else {
  //   let arrayData = array.splice(0, number);
  //   await APICall(
  //     "put",
  //     EndPoints.transactionsBatch,
  //     arrayData
  //     // eslint-disable-next-line no-loop-func
  //   ).then((response) => {
  //     if (response.status === 200 && response.data) {
  //       data = response.data;
  //     }
  //   });
  // }
};

export const addBatchRecurringRules = async (array, no = 50) => {
  const LIMIT = pLimit(5);
  let data = [];
  let count = array?.length;
  if (count > data.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("post", EndPoints.recurring_rules + "batch_add/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }

  // let allow = true;
  // while (allow) {
  //   let arrayData = array.splice(0, 50);
  //   await APICall(
  //     "post",
  //     EndPoints.recurring_rules + "batch_add/",
  //     arrayData
  //     // eslint-disable-next-line no-loop-func
  //   ).then((response) => {
  //     if (response.status === 201 && response.data) {
  //       data = [...data, ...response.data];
  //       if (array?.length >= 50 || array?.length > 0) {
  //         allow = true;
  //       } else {
  //         allow = false;
  //       }
  //     }
  //   });
  // }
};

export const updateBatchRecurringRules = async (array, no = 50) => {
  const LIMIT = pLimit(5);
  let data = [];
  let count = array?.length;
  if (count > data?.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("put", EndPoints.recurring_rules + "batch_update/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const getExternalDataSourceConnection = async (DSID) => {
  let status = null;

  await APICall(
    "get",
    EndPoints.integrations + `${DSID}/external/check-connection/`
  ).then((response) => {
    if (response.status === 200 && response.data) {
      if (response.data?.connected) {
        status = "UPDATED";
      } else {
        status = "DISCONNECTED";
      }
    } else {
      status = "UNAUTHORIZED_ACCESS";
    }
  });
  return status;
};

export const getFinApiDataSourceConnection = async (DSID) => {
  let status = null;

  await APICall(
    "get",
    EndPoints.integrations + `${DSID}/finapi/connections/`
  ).then((response) => {
    if (response.status === 200 && response.data) {
      // const _res = response?.data;
      const _res = response?.data?.[0]?.interfaces;
      const firstItem = _res?.[0];
      const lastItem = _res?.[_res?.length - 1];
      if (
        lastItem?.banking_interface !== "WEB_SCRAPER" &&
        lastItem?.user_action_required
      ) {
        status = "DISCONNECTED";
      } else if (
        firstItem?.banking_interface === "WEB_SCRAPER" &&
        firstItem?.user_action_required
      ) {
        status = "UPDATED";
      } else {
        status = response?.data?.[0]?.status;
      }
      if (_res?.length === 0) {
        status = "EMPTY";
      }
    } else {
      status = "UNAUTHORIZED_ACCESS";
    }
  });

  return status;
};

export const getDataSourceErrorById = async (DS) => {
  await queryClient.fetchQuery({
    queryKey: [
      "integrations",
      {
        data_source: DS?.uuid,
        apiType: "data_source_fetch_errors",
      },
    ],
    queryFn: async ({ signal }) => {
      const result = await APICall(
        "get",
        EndPoints.integrations + `${DS?.uuid}/logs/`
      ).then((response) => {
        if (response.status === 200 && response.data) {
          return response.data;
        }
      });
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
  });
};

export const yieldToMain = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
};

export const getCellInfo = ({
  data = [],
  total_key = "inflow",
  count_key = "inflow_count",
  isPastMonth,
  isFutureMonth,
  isCurrentMonth,
  highlightedScenarios,
  pastTotal = 0,
}) => {
  let _data = data.filter((o1) => o1[count_key] > 0);
  let selected_scenario_data =
    highlightedScenarios?.length > 0
      ? _data.filter((o1) => highlightedScenarios.includes(o1.scenario_uuid))
      : [];

  let total = {
    booked: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.bookedState.includes(item?.state) ? item[total_key] ?? 0 : 0
        ),
      0
    ),
    planned: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) ? item[total_key] ?? 0 : 0
        ),
      0
    ),
    open: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !Constant.bookedState.includes(item?.state) &&
            !Constant.plannedState.includes(item?.state)
            ? item[total_key] ?? 0
            : 0
        ),
      0
    ),
    scenario_planned: selected_scenario_data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) ? item[total_key] ?? 0 : 0
        ),
      0
    ),
  };
  let count = {
    bookedCount: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.bookedState.includes(item?.state) ? item[count_key] ?? 0 : 0
        ),
      0
    ),
    openCount: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !Constant.bookedState.includes(item?.state) &&
            !Constant.plannedState.includes(item?.state)
            ? item[count_key] ?? 0
            : 0
        ),
      0
    ),
    plannedCount: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) ? item[count_key] ?? 0 : 0
        ),
      0
    ),
    plannedSeqCount: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) && item?.planned_seq_count
            ? item["planned_seq_count"] ?? 0
            : 0
        ),
      0
    ),
  };
  let count2 = {
    plannedCount: selected_scenario_data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) ? item[count_key] ?? 0 : 0
        ),
      0
    ),
    plannedSeqCount: selected_scenario_data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) && item?.planned_seq_count
            ? item["planned_seq_count"] ?? 0
            : 0
        ),
      0
    ),
  };

  const plannedData = highlightedScenarios
    ? selected_scenario_data.filter((o1) => o1.planned_uuid)
    : [];

  const plannedUuid =
    plannedData.length === 1 ? plannedData?.[0]?.planned_uuid : null;

  const scenarios = [
    ...new Set(_data.map((o1) => o1.scenario !== "Base" && o1.scenario_uuid)),
  ];

  const bookedValue = total?.booked ?? 0;
  const openValue = total?.open ?? 0;
  const plannedValue = total?.planned ?? 0;
  const isEmpty =
    total?.booked === 0 &&
    total?.open === 0 &&
    pastTotal === 0 &&
    total?.planned === 0;
  const isEditable = Boolean(
    isEmpty ||
      (count2.plannedCount === 0 && !plannedUuid) ||
      (count2.plannedCount <= 1 && count2?.plannedSeqCount === 0 && plannedUuid)
  );

  const compareValue = isFutureMonth
    ? total.open
    : isCurrentMonth
      ? total.booked + total.open
      : total.booked;
  const compareCount = isFutureMonth
    ? count.openCount
    : isCurrentMonth
      ? count.openCount + count.bookedCount
      : count.bookedCount;
  const compareTotal = isFutureMonth
    ? total.open
    : isCurrentMonth
      ? total.booked + total.open + pastTotal
      : total.booked;
  const isPlanned =
    (compareTotal === 0 && total?.planned !== 0) ||
    (total_key === "inflow"
      ? plannedValue > compareValue
      : plannedValue < compareValue);
  const isSinglePlanned = count?.plannedCount === 1 && compareCount === 0;
  const isMultiplePlanned = count?.plannedCount > 1;
  const value =
    isPlanned && !isPastMonth
      ? plannedValue + (pastTotal || 0)
      : compareValue + (pastTotal || 0);
  const percentageChange = calculatePercentageChange(
    plannedValue,
    compareValue
  );

  const is_note_available = _data.some((o1) => o1.is_note_available);
  const is_note_user_edited = _data.some((o1) => o1.is_note_user_edited);
  const is_note_highlighted = _data.some((o1) => o1.is_note_highlighted);
  return {
    plannedUuid,
    isPlanned,
    isSinglePlanned,
    isMultiplePlanned,
    total,
    count,
    value,
    bookedValue,
    openValue,
    plannedValue,
    percentageChange,
    isEmpty,
    compareValue,
    compareCount,
    compareTotal,
    scenarios,
    isEditable,
    is_note_available,
    is_note_user_edited,
    is_note_highlighted,
  };
};

export const formatDateInWeek = (date) => {
  const weekNumber = getWeekOfMonth(date, { weekStartsOn: 1 });
  return `${formatDateToLocal(date, "MMM")} (Week ${weekNumber})`;
};

export const getGridColumn = ({
  showColumnStart = true,
  showColumnEnd = true,
  gridWidth = 50,
  stickyColumn = [],
  editable = false,
  start_date,
  end_date,
  isWeeklyTable,
}) => {
  let array = [];
  if (!start_date || !end_date) return array;
  const data = isWeeklyTable
    ? eachWeekOfInterval({
        start: new Date(start_date),
        end: new Date(end_date),
      })
    : eachMonthOfInterval({
        start: new Date(start_date),
        end: new Date(end_date),
      });
  let commonProps = {
    gridWidth,
    editable,
  };
  if (showColumnStart) {
    array.push({
      columnId: "column-start",
      isSticky: stickyColumn.includes("column-start"),
      ...commonProps,
    });
  }
  if (data?.length > 0) {
    data.forEach((element) => {
      const date = format(new Date(element), "yyyy-MM-dd");
      array.push({
        columnId: date,
        item: date,
        isSticky: stickyColumn.includes(date),
        ...commonProps,
      });
    });
  }
  if (showColumnEnd) {
    array.push({
      columnId: "column-end",
      isSticky: stickyColumn.includes("column-end"),
      ...commonProps,
    });
  }
  return array;
};

export const getRowsCell = ({
  cellStart = null,
  cellEnd = null,
  data = [],
}) => {
  let array = [];

  if (cellStart) {
    array.push(cellStart);
  }
  if (data?.length > 0) {
    data.forEach((item) => {
      array.push(item);
    });
  }
  if (cellEnd) {
    array.push(cellEnd);
  }
  return array;
};

export const getIntegrationCard = ({ ds }) => {
  if (ds?.internal_dataset) {
    const card = Constant.integrations.IntegrationsByName?.["ManualDS"]?.[0];
    return card;
  } else if (ds?.note) {
    const card = Constant.integrations.IntegrationsByName?.[ds?.note]?.[0];
    return card;
  } else {
    const card = Constant.integrations.IntegrationsByName?.["ManualDS"]?.[0];
    return card;
  }
};

export const getStaffQueryKey = (data) => {
  return ["staff"];
};
