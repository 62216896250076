import * as React from "react";

import { Color, Constant, Fonts } from "../../Helper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Tooltip,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import OnBoardingFunctions from "./OnBoardingFunctions";
import { useNavigate } from "react-router";
import { isPlanExpired, remToPx, thinScrollbarStyle } from "../../Helper/data";
import { useTranslation } from "react-i18next";
import { differenceInDays } from "date-fns";
import Translate from "../../hooks/HOC/Translate";
import useWidth from "../../hooks/useWidth";
import HeaderIconView from "../Header/Component/HeaderIconView";
import CustomPopover from "../PopOver";
import { setIsOnBoardingOverlayOpen } from "../../store/slices/common";
import { useDispatch } from "react-redux";

function CircularProgressWithLabel({ count, isStartPage, ...props }) {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontWeight={"fontWeightMediumBold"}
          color={theme.palette.color.slate[700]}
          sx={{
            fontSize: { xs: "0.45rem", small: "0.7rem" },
            lineHeight: { xs: "0.45rem", small: "0.7rem" },
          }}
        >
          {count}
        </Typography>
      </Box>
    </Box>
  );
}

const OnbButtonView = React.memo((props) => {
  const small = useMediaQuery("(min-width:600px)");
  const viewRef = React.useRef(null);
  const theme = useTheme();
  const currentWidth = useWidth();
  const isOpenFirstTime = React.useRef(false);
  const first_login = useSelector(
    (state) => state.settingsSlice?.profile?.first_login
  );
  const onBoardingList = useSelector(
    (state) => state?.globalSlice?.onBoardingList
  );

  const [progress, setProgress] = React.useState(0);

  const OnBCount = React.useMemo(() => {
    let completedCount = 0;
    let totalCount = 0;
    onBoardingList?.forEach((item) => {
      if (item?.checked) {
        completedCount += item?.children?.length;
      } else {
        item?.children?.forEach((child) => {
          if (child?.checked) {
            completedCount += 1;
          }
        });
      }
      item?.children?.forEach((child) => {
        totalCount += 1;
      });
    });
    return { completedCount, totalCount };
  }, [onBoardingList]);

  React.useEffect(() => {
    if (first_login && !isOpenFirstTime.current) {
      isOpenFirstTime.current = true;
      props?.popup_state?.open(viewRef.current);
    }
  }, [first_login, props?.popup_state]);

  React.useEffect(() => {
    setProgress((OnBCount?.completedCount * 100) / OnBCount?.totalCount);
  }, [OnBCount?.completedCount, OnBCount?.totalCount]);

  const remaining_step =
    (OnBCount?.totalCount || 0) - (OnBCount?.completedCount || 0);

  return (
    <HeaderIconView
      tooltip=""
      icon={
        <Box
          ref={viewRef}
          sx={{
            display: "flex",
            alignItems: "center",
            mx: "0.5rem",
            "&:hover": {
              "& .MuiTypography-root": {
                color: theme.palette.primary.main,
              },
            },
          }}
        >
          <CircularProgressWithLabel
            count={remaining_step}
            value={progress}
            size={remToPx(
              currentWidth,
              props?.isStartPage || small ? 1.5 : 1.25
            )}
            color={"inherit"}
            isStartPage={props?.isStartPage}
          />

          <Tooltip
            title={
              <Translate
                i18nkey={"Onboarding_steps"}
                values={{
                  remaining_step: remaining_step,
                }}
              />
            }
          >
            <Stack>
              <Typography
                variant="h6"
                mx="0.5rem"
                fontWeight={"fontWeightMediumBold"}
                sx={{
                  fontSize: { xs: "0.65rem", small: "0.9rem" },
                  lineHeight: { xs: "0.65rem", small: "1rem" },
                  color: theme.palette.color.slate[700],
                }}
              >
                <Translate i18nKey={"Quick Start"} />
              </Typography>
            </Stack>
          </Tooltip>
        </Box>
      }
      sx={{
        mr: "1rem",
        p: { xs: "0.25rem", small: "0.5rem", s2133: "0.75rem" },
        color: theme.palette.color.slate[700],
        backgroundColor: theme.palette.color.slate[200],
      }}
    />
  );
});

export default function OnBoardingStepper({ isStartPage }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const btnRef = React.useRef(null);
  const onbRef = React.useRef();
  const [expanded, setExpanded] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onBoardingList = useSelector(
    (state) => state?.globalSlice?.onBoardingList
  );
  const first_name = useSelector(
    (state) => state.settingsSlice?.profile?.first_name
  );
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);

  const isOnBoardingOverlayOpen = useSelector(
    (state) => state.commonSlice?.isOnBoardingOverlayOpen
  );

  const isEnglishLanguage = locale !== "de_DE";

  React.useEffect(() => {
    setExpanded(onBoardingList?.[0]?.uuid);
  }, []);

  React.useEffect(() => {
    if (isOnBoardingOverlayOpen) {
      onClickPopOver();
    } else {
      onClosePopOver();
    }
  }, [isOnBoardingOverlayOpen]);

  //functions
  const onClickPopOver = () => {
    setAnchorEl(btnRef.current);
  };

  const onClosePopOver = () => {
    if (Boolean(anchorEl)) {
      setAnchorEl(null);
      dispatch(setIsOnBoardingOverlayOpen(false));
    }
  };

  

  return (
    <CustomPopover
      type="anchor"
      ref={btnRef}
      anchorEl={anchorEl}
      onClose={onClosePopOver}
      onClick={onClickPopOver}
      button={<OnbButtonView isStartPage={isStartPage} />}
      popupProps={{
        anchorReference: "anchorPosition",
        anchorPosition: { top: 0, left: 0 },
      }}
      sxStyle={{ mt: 0 }}
      paperStyle={{
        backgroundColor: "transparent",
        maxHeight: "100%",
        height: "100%",
        left: "unset !important",
        right: 0,
        top: "0px !important",
        borderRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "fit-content",
          width: "30rem",
          height: "99.95vh",
          position: "relative",
          backgroundImage: `linear-gradient(0deg, ${theme.palette.primary[500]} 0%, ${theme.palette.primary.dark} 100%)`,
          ...thinScrollbarStyle,
        }}
      >
        <Stack p="2rem">
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              fontSize: "1.1rem",
              fontFamily: Fonts.Text,
              color: Color.white,
            }}
          >
            <Translate
              i18nkey={"main_header_onboarding_overlay_msg"}
              values={{ name: first_name }}
            />
          </Typography>
        </Stack>
        <Box
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
          sx={{
            ...thinScrollbarStyle,
            "& .MuiAccordion-root:Before": {
              display: "none",
            },
          }}
        >
          <OnBoardingFunctions ref={onbRef} />
          {onBoardingList?.map((item, index) => (
            <RootItem
              key={item?.uuid}
              item={item}
              expanded={expanded === item?.uuid}
              setExpanded={setExpanded}
              onbRef={onbRef}
              parentIndex={index}
              isEnglishLanguage={isEnglishLanguage}
            />
          ))}

        </Box>
        <SubscribeView />

      </Box>
    </CustomPopover>
  );
}

const RootItem = ({ expanded, setExpanded, item, onbRef, parentIndex, isEnglishLanguage }) => {

  const onChange = () => {
    setExpanded(expanded ? false : item?.uuid);
  };
  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={expanded}
      onChange={onChange}
      sx={{
        px: "2rem",
        border: "none",
        backgroundColor: "transparent",
        boxShadow: "none !important",
      }}
    >
      <AccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "1rem", color: Color.white }}
          />
        }
        aria-controls={`${item?.uuid}-content`}
        id={`${item?.uuid}-header`}
        sx={{
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
          "& .MuiAccordionSummary-content": {
            marginLeft: "0.5rem",
          },
          "& p": {
            marginBlock: "0px !important",
          },
        }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            color: Color.white,
            fontSize: "1rem",
            display: "inline-flex",
            textDecoration: item?.checked ? "line-through" : "none",
          }}
        >
          {isEnglishLanguage ? item?.title_en : item?.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderRadius: 2,
          backgroundColor: Color.white,
          p: "1.5rem",
          mx: "0.75rem",
        }}
      >
        {item?.children?.map((subItem, index) => {
          const activeBtn =
            !subItem?.checked &&
            (parentIndex === 1 ||
              !item?.children?.[index - 1] ||
              item?.children?.[index - 1]?.checked);
          return (
            <SubItem
              key={subItem?.uuid}
              subItem={subItem}
              isLastItem={item?.children?.length - 1 === index}
              activeBtn={activeBtn}
              onbRef={onbRef}
              parentIndex={parentIndex}
              isEnglishLanguage={isEnglishLanguage}
            />
          );
        })}
      </AccordionDetails>
    </Accordion >
  );
};

const SubItem = ({ subItem, isLastItem, activeBtn, onbRef, parentIndex, isEnglishLanguage }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          flexShrink: 0,
          color: Color.themeColor2,
          fontFamily: Fonts.Text,
          fontWeight: 600,
          fontSize: "1rem",
          display: "inline-flex",
          textDecoration: subItem?.checked ? "line-through" : "none",
        }}
      >
        <CheckCircleIcon
          sx={{
            color: subItem?.checked
              ? Color.tailwind.green[500]
              : Color.tailwind.slate[200],
            mr: "0.75rem",
          }}
        />
        {isEnglishLanguage ? subItem?.title_en : subItem?.title}

      </Typography>
      <Typography
        // dangerouslySetInnerHTML={{ __html: content }}
        dangerouslySetInnerHTML={{ __html: isEnglishLanguage ? subItem?.content_en : subItem?.content }}
        color="color.description"
        sx={{ ml: "2.25rem", fontSize: "0.9rem", my: 0 }}
      >

      </Typography>
      <ButtonView
        subItem={subItem}
        defaultView={!activeBtn && !subItem?.checked}
        activeBtn={activeBtn}
        onbRef={onbRef}
        parentIndex={parentIndex}
        isEnglishLanguage={isEnglishLanguage}
      />
      {isLastItem ? null : <Divider sx={{ my: "1rem" }} />}
    </Box>
  );
};

const ButtonView = ({
  subItem,
  activeBtn = false,
  defaultView = false,
  onbRef,
  parentIndex,
  isEnglishLanguage
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);
  const isDefaultActive = parentIndex === 1;

  const onClickShow = () => {
    setShow(true);
  };

  const onClickActivate = (e) => {
    e.stopPropagation();
    onbRef.current?.updateOnboardingList({
      uuid: subItem?.uuid,
      payload: {
        checked: false,
      },
    });
  };

  const onClickDismiss = (e) => {
    e.stopPropagation();
    setShow(false);
    onbRef.current?.updateOnboardingList({
      uuid: subItem?.uuid,
      payload: {
        checked: true,
      },
    });
  };

  const onClickAdd = () => {
    onbRef.current?.onClickAction(subItem);
  };

  return (
    <Stack ml="2.25rem" mt="1rem">
      {!show && defaultView ? (
        <Button
          onClick={onClickShow}
          variant="outlined"
          sx={{
            width: "fit-content",
            color: Color.tailwind.slate[600],
            py: "0.75rem",
            px: "2rem",
            fontFamily: Fonts.Text,
            fontSize: "0.9rem",
            lineHeight: "0.9rem",
            borderRadius: 2,
            display: "inline-flex",
            textTransform: "initial",
          }}
        >
          {t("Show me how")}
        </Button>
      ) : activeBtn || show ? (
        <Stack direction="row" alignItems={"center"}>
          <Button
            onClick={onClickAdd}
            variant={isDefaultActive ? "outlined" : "contained"}
            sx={{
              backgroundColor: isDefaultActive
                ? "transparent"
                : theme.palette.primary.dark,
              color: isDefaultActive ? Color.tailwind.slate[600] : Color.white,
              py: "0.75rem",
              px: "2rem",
              fontFamily: Fonts.Text,
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
              borderRadius: 2,
              display: "inline-flex",
              textTransform: "initial",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: isDefaultActive
                  ? "transparent"
                  : theme.palette.primary.darkHover,
              },
            }}
          >
            {isEnglishLanguage ? subItem?.button_title_en : subItem?.button_title_de}
          </Button>
          <Button
            onClick={onClickDismiss}
            variant="text"
            sx={{
              width: "fit-content",
              color: Color.tailwind.slate[500],
              py: "0.75rem",
              px: "1rem",
              ml: "1rem",
              fontFamily: Fonts.Text,
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
              borderRadius: 2,
              display: "inline-flex",
              textTransform: "initial",
            }}
          >
            {t("Skip")}
          </Button>
        </Stack>
      ) : (
        <Button
          onClick={onClickActivate}
          variant="text"
          sx={{
            width: "fit-content",
            color: Color.tailwind.slate[500],
            py: "0.75rem",
            px: "0.5rem",
            fontFamily: Fonts.Text,
            fontSize: "0.9rem",
            lineHeight: "0.9rem",
            borderRadius: 2,
            display: "inline-flex",
            textTransform: "initial",
          }}
        >
          {t("Reactivate")}
        </Button>
      )}
    </Stack>
  );
};

const SubscribeView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const day = differenceInDays(
    new Date(subscription?.expiry_date ?? new Date()),
    new Date()
  );

  const getText = () => {
    let text = "";
    if (isPlanExpired(subscription)) {
      text = t("Your subscription plan  Expired - Please upgrade you plan.", {
        subscription: subscription?.name,
      });
      return text;
    }
    if (subscription?.name === "Trial") {
      if (day >= 0 && day <= 2) {
        text = t(
          "Your subscription plan will Expire, Book now for a 25% discount.",
          {
            subscription: subscription?.name,
            day: day + 1,
            day_plural: day > 0 ? t("day_s") : t("day"),
          }
        );
        return text;
      }
      text = t("You can still test finban, Book now for a 25% discount.", {
        subscription: subscription?.name,
        day: day + 1,
        day_plural: day > 0 ? t("day_s") : t("day"),
      });
    } else {
      if (day >= 0 && day <= 2) {
        text = t("Your subscription plan will Expired in day", {
          subscription: subscription?.name,
          day: day + 1,
          day_plural: day > 0 ? t("day_s") : t("day"),
        });
        return text;
      }
    }
    return text;
  };

  const onBookCall = () => {
    window.open("https://finban.io/contact-us/", "_blank");
  };

  const onSubscribe = () => {
    navigate("/settings/subscription");
  };

  const subscriptionText = React.useMemo(() => {
    return getText();
  }, [subscription]);

  return (
    <Stack
      direction={"column"}
      backgroundColor={theme.palette.primary.main}
      p="2rem"
      mt="2rem"
    >
      {subscriptionText ? (
        <>
          <Typography
            sx={{
              flexShrink: 0,
              color: Color.white,
              fontFamily: Fonts.Text,
              fontWeight: 700,
              fontSize: "1rem",
              display: "inline-flex",
            }}
          >
            {subscriptionText}
          </Typography>
          <Button
            onClick={onSubscribe}
            sx={{
              backgroundColor: Color.tailwind.blue[600],
              color: Color.white,
              py: "0.75rem",
              px: "2rem",
              my: "1rem",
              fontFamily: Fonts.Text,
              fontSize: "0.9rem",
              lineHeight: "0.9rem",
              borderRadius: 2,
              display: "inline-flex",
              textTransform: "initial",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: Color.tailwind.blue[600],
              },
            }}
          >
            Subscribe now
          </Button>
        </>
      ) : null}
      <Typography
        sx={{
          flexShrink: 0,
          color: Color.white,
          fontFamily: Fonts.Text,
          fontWeight: 700,
          fontSize: "1rem",
          display: "inline-flex",
        }}
      >
        Need help?
      </Typography>
      <Button
        onClick={onBookCall}
        sx={{
          backgroundColor: "transparent",
          color: Color.white,
          border: `1px solid ${Color.white}`,
          py: "0.75rem",
          px: "2rem",
          mt: "1rem",
          fontFamily: Fonts.Text,
          fontSize: "0.9rem",
          lineHeight: "0.9rem",
          borderRadius: 2,
          display: "inline-flex",
          textTransform: "initial",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "&:hover": {
            backgroundColor: alpha(Color.white, 0.1),
          },
        }}
      >
        Book 10-min intro call
      </Button>
    </Stack>
  );
};
