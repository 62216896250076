import { Button, Typography, Grid, useTheme } from "@mui/material";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { AiFillFilePdf } from "react-icons/ai";
import { PiFileCsvFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { writeFile, utils } from "xlsx";
import { format } from "date-fns";
import React from "react";

import CustomPopoverScratch from "../../../../components/PopOver/CustomPopover";
import useStatusHook from "../../../../hooks/useStatusHook";
import Translate from "../../../../hooks/HOC/Translate";
import store from "../../../../store";

const exportButtonData = [
  {
    id: 0,
    title: "Excel Export",
    icon: <RiFileExcel2Fill color="green" />,
    value: "Excel",
  },
  // {
  //   id: 1,
  //   title: "CSV Export",
  //   icon: <PiFileCsvFill color="#475569" />,
  //   value: "CSV",
  // },
  // {
  //   id: 2,
  //   title: "PDF Export",
  //   icon: <AiFillFilePdf color="red" />,
  //   value: "PDF",
  // },
];
const FirstColumnKeys = [
  "start_liquidity",
  "net_change",
  "inflow",
  "outflow",
  "end_liquidity",
  "vat",
];

const ExportDialogView = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  //redux
  const planningTableData = useSelector(
    (state) => state.globalSlice?.planningTableData
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  const transaction_monthly_chart = useStatusHook("transaction_monthly_chart");
  const pastTransaction_monthly_statistic = useStatusHook(
    "Transaction_monthly_statistic"
  );
  const tableLoading =
    transaction_monthly_chart?.isFetching ||
    pastTransaction_monthly_statistic?.isFetching;
  if (!isAllHeaderApiFetched || !planningTableData || tableLoading) {
    return null;
  }
  //functions
  const handleExport = async (format) => {
    if (format === "Excel") {
      exportToExcel();
    }
  };

  const exportToExcel = () => {
    let jsonData = [];

    FirstColumnKeys?.forEach((firstColumn, index) => {
      const table_section = planningTableData?.[firstColumn];
      table_section?.forEach((row) => {
        let rowArray = [];
        if (index === 0) {
          row?.cells?.forEach((cell) => {
            rowArray.push(
              cell?.item?.date
                ? format(new Date(cell?.item?.date), "MMM yy")
                : ""
            );
          });
          jsonData.push(rowArray);
          jsonData.push([]);
          rowArray = [];
        }
        row?.cells?.forEach((cell) => {
          rowArray.push(cell?.item?.value);
        });

        jsonData.push(rowArray);
      });
      jsonData.push([]);
    });

    const worksheet = utils.aoa_to_sheet(jsonData);
    //for styling
    // jsonData.forEach((row, rowIndex) => {
    //   row.forEach((cellValue, cellIndex) => {
    //     const cellRef = utils.encode_cell({ r: rowIndex, c: cellIndex });

    //     if (rowIndex === 0) {
    //       worksheet[cellRef].s = {
    //         font: { bold: true, color: { rgb: "0000FF" } },
    //       };
    //     }

    //     // if (FirstColumnKeys[index] === "inflow") {
    //     //   worksheet[cellRef].s = {
    //     //     fill: { fgColor: { rgb: "FFFF00" } }, // Yellow background for "inflow"
    //     //     font: { color: { rgb: "FF0000" } }, // Red text for "inflow"
    //     //   };
    //     // }

    //     // if (rowIndex > 1 && cellValue < 0) {
    //     //   // Example condition
    //     //   worksheet[cellRef].s = {
    //     //     font: { italic: true, color: { rgb: "FF0000" } }, // Italic red font for negative values
    //     //   };
    //     // }
    //   });
    // });
    const workbook = utils.book_new();
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;
    utils.book_append_sheet(workbook, worksheet, dataSetData?.title);
    writeFile(workbook, `finban-${dataSetData?.title}-planning.xlsx`);
  };

  return (
    <CustomPopoverScratch
      button={<ButtonView />}
      paperStyle={{
        borderRadius: "0.5rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Grid container spacing={2} sx={{ p: "1rem", width: "20rem" }}>
        <Grid
          item
          xs={12}
          sx={{
            pl: "1rem",
          }}
        >
          <Typography variant="h6">{t("export_overlay_title")}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            mb: "1rem",
            color: theme.palette.color.description,
            pl: "1rem",
            fontSize: "0.8rem",

          }}
        >
          <Translate i18nKey="export_overlay_planning_view_description" />
        </Grid>
        {exportButtonData?.map((data) => (
          <Grid item key={data.id} xs={12}>
            <Button
              variant="text"
              startIcon={data.icon}
              onClick={() => handleExport(data.value)}
              sx={{
                px: "1rem",
                py: ".75rem",
                width: "100%",
                justifyContent: "flex-start",
                color: theme.palette.color.slate[700],
                "&:hover": {
                  backgroundColor: theme.palette.color.slate[100],
                },
              }}
            >
              {t(data?.title)}
            </Button>
          </Grid>
        ))}
      </Grid>
    </CustomPopoverScratch>
  );
};

export default ExportDialogView;

const ButtonView = ({ popup_state }) => {
  const { t } = useTranslation();
  const onClick = () => {
    setTimeout(() => {
      popup_state?.open();
    }, 500);
  };

  return (
    <Button onClick={onClick} variant="outlined">
      {t("Export")}
    </Button>
  );
};
