import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isFetching: false,
  isLoading: false,
  stageText: false,
  testLoading: false,
  isDailyChartLoading: false,
  isMonthChartLoading: false,
  isTableLoading: false,
  refreshData: null,
  refreshColumnData: null,
  isKanbanUpdated: false,
  refreshBalance: null,
  isFirstLoading: true,
};

const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    setIsKanbanUpdated: (state, action) => {
      state.isKanbanUpdated = action.payload;
    },
    setRefreshColumnData: (state, action) => {
      state.refreshColumnData = action.payload;
    },
    setIsTableLoading: (state, action) => {
      state.isTableLoading = action.payload;
    },
    setRefreshData: (state, action) => {
      state.refreshData = action.payload;
    },
    setIsDailyChartLoading: (state, action) => {
      state.isDailyChartLoading = action.payload;
    },
    setIsMonthChartLoading: (state, action) => {
      state.isMonthChartLoading = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsFatching: (state, action) => {
      state.isFetching = action.payload;
    },
    setStageLoadingText: (state, action) => {
      state.stageText = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setTestLoading: (state, action) => {
      state.testLoading = action.payload || !state.testLoading;
    },
    setRefreshBalance: (state, action) => {
      state.refreshBalance = action.payload;
    },
    setFirstLoading: (state, action) => {
      state.isFirstLoading = action.payload;
    },
  },
});
export const {
  setIsKanbanUpdated,
  setIsMonthChartLoading,
  setIsDailyChartLoading,
  setLoading,
  setStageLoadingText,
  setIsFatching,
  setIsLoading,
  setTestLoading,
  setRefreshData,
  setRefreshColumnData,
  toggleRefreshDataInflow,
  toggleRefreshDataOutflow,
  setIsTableLoading,
  setRefreshBalance,
  setFirstLoading,
} = appSlice.actions;
export default appSlice;
