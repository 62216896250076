import {
  AppBar as MuiAppBar,
  ListItemButton,
  DialogActions,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Container,
  Checkbox,
  Collapse,
  ListItem,
  MenuItem,
  Divider,
  Popover,
  Toolbar,
  Tooltip,
  Avatar,
  styled,
  Badge,
  Stack,
  Modal,
  Chip,
  List,
  Menu,
  Box,
} from "@mui/material";
import OndemandVideoTwoToneIcon from "@mui/icons-material/OndemandVideoTwoTone";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SupportTwoToneIcon from "@mui/icons-material/SupportTwoTone";
import RocketTwoToneIcon from "@mui/icons-material/RocketTwoTone";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { MdBusiness, MdSecurity } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import PercentIcon from "@mui/icons-material/Percent";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { RiPresentationFill } from "react-icons/ri";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import MapIcon from "@mui/icons-material/Map";
import { alpha } from "@mui/material/styles";
import { PiMagicWand } from "react-icons/pi";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { TbPlug } from "react-icons/tb";
import { Helmet } from "react-helmet";

import {
  setIsAccountSelectOverlayOpen,
  setIsFirstLoginOverlayOpen,
  setIsUpdatesOverlayOpen,
  setPlanExpiredShow,
  setOnBoardingList,
  setJoyRideStatus,
} from "../../store/slices/global";
import {
  setStageLoadingText,
  setRefreshBalance,
  setTestLoading,
  setIsLoading,
} from "../../store/slices/appmain";
import {
  thinScrollbarStyle,
  removeAllCookies,
  isPlanExpired,
} from "../../Helper/data";
import {
  setMainDrawer,
  setProfile,
  setAddFrom,
} from "../../store/slices/settings";
import { setMaintenanceMode, toggleDevMode } from "../../store/slices/common";
import initialData, { updateLanguage } from "../../Helper/data";
import { Fonts, Images, Color, Constant } from "../../Helper";
import { setSteps, startTour } from "../../store/slices/tour";
import OnBoardingStepper from "../Stepper/onBoardingStepper";
import HeaderIconView from "./Component/HeaderIconView";
import OverlayHeader from "./../Overlay/OverlayHeader";
import DatasetHeader from "../Header/DatasetHeader";
import UpgradePlanCard from "./../UpgradePlanCard";
import CustomModal from "./../Model/CustomModal";
import ComponentLoader from "../ComponentLoader";
import EndPoints from "../../APICall/EndPoints";
import authSlice from "../../store/slices/auth";
import packageJson from "../../../package.json";
import CustomLink from "./../CustomLink";
import { queryClient } from "../../App";
import APICall from "../../APICall";
import i18n from "../../i18n";
import Icon from "../Icon";

const iconStyle = {
  fontSize: "1.2rem",
};

const settingsMenu = [
  {
    id: 13,
    title: "Subscription",
    icon: (
      <Icon
        icon={<BsFillRocketTakeoffFill />}
        fontSize={"1.3rem"}
        color="inherit"
      ></Icon>
    ),
    divider: true,
    url: "/settings/subscription",
  },

  {
    id: 3,
    title: "Give Feedback",
    icon: <FeedbackIcon sx={iconStyle} />,
  },
  {
    id: 4,
    title: "Logout",
    icon: <LogoutIcon sx={iconStyle} />,
  },
];

const generalSettingMenu = [
  {
    id: 0,
    title: "Personal",
    icon: <PermIdentityIcon sx={iconStyle} />,
    divider: false,
    url: "/settings/personal",
  },
  {
    id: 1,
    title: "Security",
    icon: (
      <Icon icon={<MdSecurity />} fontSize={"1.3rem"} color="inherit"></Icon>
    ),
    divider: true,
    url: "/settings/security",
  },
  {
    id: 2,
    title: "Company Settings",
    icon: <SettingsIcon sx={iconStyle} />,
    divider: false,
    url: "/settings/general",
  },
  {
    id: 3,
    title: "Users",
    icon: <PeopleAltIcon sx={iconStyle} />,
    divider: true,
    url: "/settings/users",
  },
  {
    id: 4,
    title: "Bank & Integrations",
    icon: <Icon icon={<TbPlug />} fontSize={"1.3rem"} color="inherit"></Icon>,
    divider: false,
    url: "/settings/Integrations",
  },
  {
    id: 5,
    title: "datasets",
    icon: (
      <Icon icon={<MdBusiness />} fontSize={"1.3rem"} color="inherit"></Icon>
    ),
    divider: true,
    url: `/settings/${initialData?.path?.organizations}`,
  },

  {
    id: 8,
    title: "Rule_plural",
    icon: <PiMagicWand style={iconStyle} />,
    divider: false,
    url: "/settings/rules",
  },
  {
    id: 11,
    title: "Vats",
    icon: <PercentIcon sx={iconStyle} />,
    divider: true,
    url: "/settings/vats",
  },
];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: Constant.DRAWER_WIDTH,
    width: `calc(100% - ${Constant.DRAWER_WIDTH})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const s1280 = useMediaQuery("(min-width:1280px)");
  const isStartPage =
    window?.location?.pathname?.includes("/dashboard") ||
    window?.location?.pathname === "/";

  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );

  const handleDrawerOpen = () => {
    dispatch(setMainDrawer(true));
  };

  const onClickDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <AppBar
      open={!s1280 && isMainDrawerOpen}
      variant={"div"}
      position="static"
      color="transparent"
      sx={{
        backgroundColor: theme.palette.color.slate[100],
        borderRadius: 0,
        borderBottom: !isStartPage
          ? `1px solid ${theme.palette.color.slate[300]}`
          : 0,
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          px: "0 !important",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: Constant.HEADER_HEIGHT,
        }}
      >
        <Toolbar disableGutters sx={{ display: "contents" }}>
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            sx={{
              display: {
                xs: isMainDrawerOpen || isStartPage ? "none" : "flex",
                s1280: "none",
              },
            }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
          <LogoView />
          <Box sx={{ width: { xs: "0rem", s1920: "8rem" } }}></Box>
          <DatasetHeader />
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexGrow: 0,
            }}
          >
            {/* <TodoView /> */}
            {profile?.email?.includes("@finban.io") ? (
              <OnBoardingStepper isStartPage={isStartPage} />
            ) : null}
            {(
              Constant?.isProd ? profile?.email?.includes("@finban.io") : true
            ) ? (
              <DevPopUp />
            ) : null}
            {isStartPage ? null : (
              <HeaderIconView
                tooltip="dashboard_icon_tooltip"
                icon={<TbLayoutDashboardFilled />}
                onClick={onClickDashboard}
              />
            )}
            <SupportView />
            <ProfileOverlay />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;

const LogoView = () => {
  const navigate = useNavigate();
  const s1280 = useMediaQuery("(min-width:1280px)");

  //functions
  const onClickLogo = () => {
    navigate("/dashboard");
  };

  return (
    <Box
      onClick={onClickLogo}
      className={"header-1-step dashboard_overview_4_step"}
      sx={{
        width: { xs: "3rem", s1280: "8rem" },
        cursor: "pointer",
        mr: { xs: "0rem", s1280: "8rem" },
        ml: "1rem",
      }}
    >
      <img
        src={!s1280 ? Images.finban_logo_small : Images.finban_logo_full}
        height={"100%"}
        width={"100%"}
        alt="App_Logo"
        style={{
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

const SupportView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  //state
  const [helpOpen, setHelpOpen] = useState(false);

  //functions
  const onClickHelp = () => {
    setHelpOpen(true);
  };

  const handleClose = () => {
    setHelpOpen(false);
  };

  return (
    <>
      <CustomModal
        hideAction
        heading={t("Support")}
        open={helpOpen}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "40rem",
            position: "relative",
            p: "2.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              width: "100%",
              "& svg": {
                color: `${theme.palette.color.slate[700]} !important`,
              },
            }}
          >
            <LinkView
              icon={<RocketTwoToneIcon />}
              text={t("Support_Link_Text_1")}
              href={"https://finban.io/liquiditaetsplanung-mit-finban/"}
            />
            <ChildModal />

            <LinkView
              icon={<SupportTwoToneIcon />}
              text={t("Support_Link_Text_3")}
              href={
                "https://finban.io/documentation/faq-bekannte-probleme-fehler/"
              }
            />
            <LinkView
              icon={<MenuBookTwoToneIcon />}
              text={t("Support_Link_Text_4")}
              href={"//www.finban.io/docs"}
            />
            <LinkView
              icon={<OndemandVideoTwoToneIcon />}
              text={t("Support_Link_Text_5")}
              href={"//www.finban.io/tutorials"}
            />
            <LinkView
              icon={<EmailTwoToneIcon />}
              text={t("Support_Link_Text_6")}
              href={"mailto:support@finban.io"}
            />
            <LinkView
              icon={<MapIcon />}
              text={t("Support_Link_Text_7")}
              href={"https://finban.io/finban-roadmap/"}
            />
            {/* <LinkView
              icon={<QuizTwoToneIcon />}
              text={t("Support_Link_Text_7")}
              href={"//www.finban.io/faq"}
            /> */}
          </Box>
        </Box>
      </CustomModal>
      <HeaderIconView
        tooltip="Support"
        icon={<SupportAgentIcon />}
        onClick={onClickHelp}
        className={"header-3-step dashboard_overview_10_step"}
        sx={{ mx: "1rem" }}
      />
    </>
  );
};

const ProfileOverlay = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  //redux state
  const settings = useSelector((state) => state.settingsSlice);

  //state
  const [anchorElUser, setAnchorElUser] = useState(null);

  //functions
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e, menu) => {
    setAnchorElUser(null);
    if (menu?.title === "Logout") {
      handleLogout();
    } else if (menu?.title === "Give Feedback") {
      window.location.href = "mailto:support@finban.io";
      e.preventDefault();
    } else {
      navigate(menu?.url);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/login");
    enqueueSnackbar(t("Logout_message"), {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  return (
    <>
      <ProfilePopUp
        anchorEl={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
        handleCloseNavMenu={handleCloseNavMenu}
      />

      <HeaderIconView
        tooltip="Open settings"
        className={"header-4-step dashboard_overview_11_step"}
        icon={
          <Avatar
            sx={{
              width: !!settings?.profile?.photo
                ? { xs: "2rem", small: "3rem" }
                : { xs: "1.3rem", small: "1.7rem" },
              height: !!settings?.profile?.photo
                ? { xs: "2rem", small: "3rem" }
                : { xs: "1.3rem", small: "1.7rem" },
              color: theme.palette.color.slate[700],
              bgcolor: "transparent",
            }}
            alt={
              settings?.profile?.first_name
                ? settings?.profile?.first_name[0]
                : settings?.profile?.last_name
                  ? settings?.profile?.last_name[0]
                  : "Avatar"
            }
            src={settings?.profile?.photo}
          >
            {settings?.profile?.photo ? null : <PersonIcon />}
          </Avatar>
        }
        onClick={handleOpenUserMenu}
        sx={{
          mr: "1rem",
          p: !!settings?.profile?.photo
            ? 0
            : { xs: "0.25rem", small: "0.5rem" },
        }}
      />
    </>
  );
};

const ProfilePopUp = ({
  anchorEl,
  handleCloseUserMenu,
  handleCloseNavMenu,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  //redux state
  const settings = useSelector((state) => state.settingsSlice);

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseUserMenu}
      PaperProps={{
        elevation: 8,
      }}
      sx={{
        "& .MuiList-root": {
          width: "20rem !important",
        },
        "& .MuiPaper-root": {
          borderRadius: "1rem",
          ...thinScrollbarStyle,
        },
        "& .MuiDivider-root": {
          borderColor: "rgba(0, 0, 0, 0.08)",
        },
      }}
    >
      <Box sx={{ px: "1rem", mt: "0.5rem", backgroundColor: Color.white }}>
        <Stack>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: "0.9rem",
                  opacity: 0.9,
                  mr: "0.25rem",
                }}
              >
                Hi,
              </Typography>
              <Typography
                component="span"
                variant="h6"
                sx={{
                  fontWeight: 400,
                  fontSize: "0.9rem",
                }}
              >
                {settings?.profile?.first_name && settings?.profile?.last_name
                  ? settings?.profile?.first_name +
                    " " +
                    settings?.profile?.last_name
                  : settings?.profile?.first_name
                    ? settings?.profile?.first_name
                    : settings?.profile?.last_name
                      ? settings?.profile?.last_name
                      : ""}
              </Typography>
            </Stack>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: "0.9rem",
                opacity: 0.9,
                mr: "0.25rem",
              }}
            >
              {`${t("Version")} ${packageJson?.SW_VERSION}`}
            </Typography>
          </Stack>

          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 400,
              fontSize: "0.7rem",
              color: Color.greyText,
              mb: "0.5rem",
            }}
          >
            {settings?.profile?.email}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ px: "1rem", backgroundColor: Color.white }}>
        {generalSettingMenu?.map((setting) => (
          <Box
            key={setting?.id}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MenuItem
              onClick={(e) => handleCloseNavMenu(e, setting)}
              sx={{
                color: Color.black,
                borderRadius: "11px",
                "&:hover": {
                  color: theme.palette.primary.dark,
                  backgroundColor: theme.palette.primary[50],
                },
                "&.MuiButtonBase-root": {
                  px: "0.75rem",
                  py: "0.25rem",
                  minHeight: "2.8rem",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "2.1rem",
                  color: "inherit",
                }}
              >
                {setting?.icon}
              </ListItemIcon>
              <ListItemText
                primary={t(setting?.title)}
                sx={{
                  "& span": {
                    fontSize: "0.9rem",
                  },
                  borderRadius: "11px",
                }}
              />
            </MenuItem>
            {setting?.divider ? (
              <Divider sx={{ m: "0.5rem 2rem !important" }} />
            ) : null}
          </Box>
        ))}
      </Box>
      <SubscriptionView />

      <Box sx={{ px: "1rem", backgroundColor: Color.white }}>
        {settingsMenu?.map((setting) => (
          <Box
            key={setting?.id}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MenuItem
              onClick={(e) => handleCloseNavMenu(e, setting)}
              sx={{
                color: Color.black,
                borderRadius: "11px",
                "&:hover": {
                  color: theme.palette.primary.dark,
                  backgroundColor: theme.palette.primary[50],
                },
                "&.MuiButtonBase-root": {
                  px: "0.75rem",
                  py: "0.25rem",
                  minHeight: "2.8rem",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "2.1rem",
                  color: "inherit",
                }}
              >
                {setting?.icon}
              </ListItemIcon>
              <ListItemText
                primary={t(setting?.title)}
                sx={{
                  "& span": {
                    fontSize: "0.9rem",
                  },
                  borderRadius: "11px",
                }}
              />
            </MenuItem>
            {setting?.divider ? <Divider sx={{ m: "0px 2rem" }} /> : null}
          </Box>
        ))}
      </Box>
    </Menu>
  );
};

const SubscriptionView = () => {
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const isPlanExpire = useMemo(() => {
    return isPlanExpired(subscription);
  }, [subscription]);

  if (isPlanExpire) {
    return null;
  }
  return (
    <Box
      sx={{
        mx: "1rem",
        mb: "0.5rem",
        width: "90%",
        border: 0,
        backgroundColor: Color.white,
      }}
    >
      <UpgradePlanCard
        title="Upgrade your plan"
        description="plan_upgrade_des"
      />
    </Box>
  );
};

const LinkView = ({ icon, text, href, onClick }) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        color: Color.themeColor,
        mb: "0.5rem",
        "& .MuiSvgIcon-root": {
          fontSize: { xs: "1.15rem", isTablet: "1.6rem" },
          mr: "0.5rem",
        },
        "& a": {
          textDecoration: "none !important",
        },
      }}
    >
      {icon}
      <CustomLink
        onClick={onClick}
        text={text}
        fontSize={{ xs: "0.95rem", isTablet: "1.3rem" }}
        href={href}
        style={{ ml: "1rem", textDecoration: "none !important" }}
      />
    </Box>
  );
};

function ChildModal() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
  };
  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [open]);

  return (
    <React.Fragment>
      <LinkView
        onClick={handleOpen}
        icon={
          <Icon
            icon={<RiPresentationFill />}
            fontSize={{ xs: "1.2rem", isTablet: "1.5rem" }}
            style={{
              mr: "0.6rem",
            }}
          ></Icon>
        }
        text={t("Support_Link_Text_2")}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          zIndex: 1304,
        }}
      >
        <Box
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            minWidth: "46%",
            minHeight: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: theme.borderRadius.main,
            ...theme.thinScrollBar,
          }}
        >
          <Helmet>
            <script
              type="text/javascript"
              src="https://assets.calendly.com/assets/external/widget.js"
              async
            ></script>
          </Helmet>
          <ComponentLoader
            loading={loading}
            hideNoDataPlaceholder
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <div
            loading="lazy"
            className="calendly-inline-widget"
            data-url="https://calendly.com/finban/15min"
            style={{ minWidth: "320px", height: "720px" }}
          ></div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const TodoView = () => {
  const { t } = useTranslation();
  //state
  const [open, setOpen] = useState(false);
  const [showBlink, setShowBlink] = useState(false);
  const [isArchived, setIsArchived] = useState(true);
  const [todoList, setTodoList] = useState([]);

  //api
  const updateTodosApi = async (id, obj) => {
    await APICall("patch", EndPoints.todos + `${id}/`, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  //function
  const handleClose = () => {
    setOpen(false);
  };

  let timer;
  const handleToggle = (id) => {
    clearInterval(timer);
    setShowBlink(true);
    timer = setTimeout(() => {
      setShowBlink(false);
    }, 500);
    let data = [...todoList];
    let updatedItemList = data.map((element) => {
      if (element.uuid === id) {
        updateTodosApi(element?.uuid, { checked: !element.checked });
        return { ...element, checked: !element.checked };
      }
      return element;
    });
    setTodoList(updatedItemList);
  };

  const handleClick = (id) => {
    let data = [...todoList];
    let updatedItemList = data.map((element) => {
      if (element.uuid === id) {
        return { ...element, expanded: !element.expanded };
      }
      return element;
    });
    setTodoList(updatedItemList);
  };

  const onClickToDoLink = () => {
    setIsArchived(!isArchived);
  };

  const onClickToDo = () => {
    setOpen(true);
  };

  //render
  const TodoItemView = ({ item, index }) => {
    const labelId = `checkbox-list-secondary-label-${item?.id}`;
    return (
      <div key={item?.uuid}>
        <ListItem
          secondaryAction={
            <Checkbox
              edge="end"
              onChange={() => handleToggle(item?.uuid)}
              checked={item?.checked}
              inputProps={{ "aria-labelledby": labelId }}
              sx={{ right: "1.25rem" }}
            />
          }
          sx={{
            order: item.position,
            borderTop: index === 0 ? 0 : `1px solid ${Color.todoTopBorder}`,
            backgroundColor: item.expanded ? Color.grey100 : Color.white,
          }}
          disablePadding
        >
          <ListItemButton
            onClick={() => handleClick(item?.uuid)}
            sx={{ pl: "0.75rem" }}
          >
            <Box sx={{ width: "1.75rem" }}>
              {item?.priority === 1 ? (
                <PriorityHighIcon
                  sx={{ pt: "0.25rem", color: Color.deepOrange400 }}
                />
              ) : null}
            </Box>
            <Box
              sx={{
                width: "1.625rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item?.expanded ? <ExpandLess /> : <ExpandMore />}
            </Box>
            {item?.category?.toLowerCase() === "onboarding" ? (
              <Avatar
                alt="Remy Sharp"
                src={Images.onboarding}
                sx={{ width: "3.75rem", height: "3.75rem", mr: "0.5rem" }}
              />
            ) : item?.category?.toLowerCase() === "news" ? (
              <Avatar
                alt="Remy Sharp"
                src={Images.news}
                sx={{ width: "3.75rem", height: "3.75rem", mr: "0.5rem" }}
              />
            ) : item?.category?.toLowerCase() === "search" ? (
              <Avatar
                alt="Remy Sharp"
                src={Images.search}
                sx={{ width: "3.75rem", height: "3.75rem", mr: "0.5rem" }}
              />
            ) : item?.category?.toLowerCase() === "error" ? (
              <Avatar
                alt="Remy Sharp"
                src={Images.error}
                sx={{ width: "3.75rem", height: "3.75rem", mr: "0.5rem" }}
              />
            ) : item?.category?.toLowerCase() === "hello" ? (
              <Avatar
                alt="Remy Sharp"
                src={Images.hello}
                sx={{ width: "3.75rem", height: "3.75rem", mr: "0.5rem" }}
              />
            ) : (
              <Avatar
                alt="Remy Sharp"
                src={Images.defaultRobot}
                sx={{ width: "3.75rem", height: "3.75rem", mr: "0.5rem" }}
              />
            )}
            <Typography
              sx={{
                my: "0.5rem",
                cursor: "pointer",
                py: "0.25rem",
                borderRadius: 1,
                fontSize: "1.1rem",
                fontWeight: 600,
                fontFamily: Fonts.Text,
              }}
            >
              {item?.title}{" "}
              <Chip
                label={item?.category}
                sx={{
                  fontSize: "0.55rem",
                  fontWeight: "700",
                  fontFamily: Fonts.Text,
                  height: "1.25rem",
                  color: alpha(Color.themeColor, 0.4),
                  backgroundColor: alpha(Color.themeColor, 0.05),
                }}
              />
            </Typography>
          </ListItemButton>
        </ListItem>
        <Collapse in={item?.expanded} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{ mb: index === todoList.length - 1 ? "1rem" : 0 }}
          >
            <ListItemButton
              sx={{
                pl: "3rem",
                py: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box
                variant="p"
                component={"p"}
                dangerouslySetInnerHTML={{ __html: item.content }}
                sx={{
                  fontSize: "1.1rem",
                  color: "#000",
                  "& p": {
                    fontSize: "1.1rem",
                    fontWeight: 400,
                    fontFamily: Fonts.Text,
                    pr: "1.25rem",
                  },
                }}
              ></Box>
            </ListItemButton>
          </List>
        </Collapse>
      </div>
    );
  };

  return (
    <>
      <CustomModal hideAction hideClose open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xs: "80vw", isTablet: "46.875rem" },
            height: { xs: "60vh", isTablet: "43.75rem" },
            position: "relative",
          }}
        >
          <OverlayHeader
            subTitle={isArchived ? "# To-Do's" : "# Archived To-Do's"}
            onClose={handleClose}
            showBlink={showBlink}
          />
          <List
            dense
            component="div"
            aria-labelledby="nested-list-subheader"
            sx={{
              width: "100%",
              height: { xs: "50vh", isTablet: "42.5rem" },
              backgroundColor: Color.BodyBG,
              pt: "1rem",
              p: !isArchived && 0,
              overflow: "auto",
            }}
          >
            {todoList?.map((item, index) => {
              if (isArchived && !item.checked) {
                return (
                  <TodoItemView key={item.uuid} item={item} index={index} />
                );
              } else if (!isArchived && item.checked) {
                return (
                  <TodoItemView key={item.uuid} item={item} index={index} />
                );
              } else {
                return null;
              }
            })}
          </List>
        </Box>
        <DialogActions sx={{ backgroundColor: Color.FooterBG, p: "1.5rem" }}>
          <CustomLink
            onClick={onClickToDoLink}
            text={isArchived ? "Archived To-Do's" : "To-Do's"}
            fontSize={"0.8rem"}
            to={"#"}
            showBlink={showBlink}
          />
        </DialogActions>
      </CustomModal>

      <Tooltip title={t("tooltip_finbot_icon")}>
        <Box
          className={"header-2-step dashboard_overview_9_step"}
          onClick={onClickToDo}
          sx={{
            display: "flex",
            alignItems: "center",
            p: "0.5rem",
            height: "2.375rem",

            "&:hover": {
              borderRadius: 16,
              opacity: 1,
              backgroundColor: Color.navMainHoverBg,
            },
          }}
        >
          <Badge
            badgeContent={todoList?.filter((o1) => !o1?.checked)?.length}
            color="error"
            sx={{
              "& .MuiBadge-badge": {
                transform: {
                  xs: "scale(0.7) translate(50%, -50%)",
                  small: "scale(0.8) translate(50%, -50%)",
                  isTablet: "scale(1) translate(50%, -50%)",
                },
                transformOrigin: "100% 0%",
              },
            }}
          >
            <SmartToyOutlinedIcon
              htmlColor={Color.white}
              sx={{
                fontSize: {
                  xs: "1.5rem",
                  small: "1.8rem",
                  isTablet: "2rem",
                },
              }}
            />
          </Badge>
        </Box>
      </Tooltip>
    </>
  );
};

const DevPopUp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isDevMode = useSelector((state) => state.commonSlice?.isDevMode);
  const dataSource = useSelector((state) => state.globalSlice?.dataSource);
  const joyRideStatus = useSelector(
    (state) => state.globalSlice?.joyRideStatus
  );

  //api
  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const getTodos = async () => {
    await APICall("get", EndPoints.todos).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(
          setOnBoardingList(
            response.data?.results.filter(
              (o1) => o1.category?.toLowerCase() === "onboarding"
            )
          )
        );
      }
    });
  };

  //functions
  const refetchAllTranslations = () => {
    const promises = i18n.options.supportedLngs
      ?.filter((lang) => lang !== "cimode")
      ?.map((lang) => {
        return i18n.reloadResources(lang);
      });

    Promise.all(promises).then(() => {
      dispatch(setRefreshBalance(Date.now()));
    });
  };

  const onClickFunction = async (type, popupState) => {
    popupState?.close();
    if (type === "browser_cache") {
      removeAllCookies();
      if ("caches" in window) {
        const cacheKeys = await window.caches.keys();
        await Promise.all(
          cacheKeys.map((key) => {
            window.caches.delete(key);
          })
        );
      }
      setTimeout(() => {
        window.location.replace(window.location.href);
      }, 500);
    }

    if (type === "dataset_header" || type === "reports") {
      let joyRideStatusFilter = joyRideStatus?.filters;
      if (joyRideStatusFilter) {
        let filterObj = {
          ...joyRideStatus,
          data_type: "transaction",
          filters: { ...joyRideStatusFilter, [type]: false },
        };
        setTimeout(() => {
          dispatch(setSteps({ key: type, t }));
          dispatch(startTour());
          dispatch(setJoyRideStatus(filterObj));
        }, 0);
      }
    }

    if (type === "reset_cache") {
      queryClient.resetQueries();
    }
    if (type === "reset_integration_cache") {
      let options = {
        predicate: (query) => query.queryKey[0] === "integrations",
      };
      queryClient.resetQueries(options);
    }
    if (type === "test_loading") {
      dispatch(setTestLoading());
    }
    if (type === "open_first_login_overlay") {
      dispatch(setIsFirstLoginOverlayOpen(true));
    }
    if (type === "open_updates_overlay") {
      dispatch(setIsUpdatesOverlayOpen(true));
    }
    if (type === "open_expired_plan_overlay") {
      dispatch(
        setPlanExpiredShow({
          title: "plan_expired_overlay_title",
          desc: "plan_expired_overlay_desc",
        })
      );
    }
    if (type === "open_maintenance_page") {
      dispatch(setMaintenanceMode(true));
    }
    if (type === "open_account_overlay") {
      dispatch(
        setAddFrom({
          integration_id: dataSource?.find((o1) => o1.type === 12)?.uuid,
          targetUrl: window.location.pathname,
        })
      );
      dispatch(
        setIsAccountSelectOverlayOpen({ open: true, bankType: "finApi" })
      );
    }
    if (type === "first_login") {
      dispatch(setProfile({ ...profile, first_login: true }));
      dispatch(
        setIsAccountSelectOverlayOpen({ open: true, bankType: "finApi" })
      );
      updateProfile({ first_login: true });
    }

    if (type === "language") {
      let obj = { locale: profile.locale !== "de_DE" ? "de_DE" : "en_US" };
      updateLanguage(obj.locale);
      dispatch(
        setProfile({
          ...profile,
          ...obj,
        })
      );
      updateProfile(obj);
    }
    if (type === "beta_enabled") {
      let obj = { beta_enabled: !profile.beta_enabled };
      dispatch(
        setProfile({
          ...profile,
          ...obj,
        })
      );
    }
    if (type === "toggleDevMode") {
      dispatch(toggleDevMode());
    }
    if (type === "user_role") {
      let obj = { is_owner: !profile.is_owner };
      dispatch(
        setProfile({
          ...profile,
          ...obj,
        })
      );
    }
    if (type === "test_spin") {
      dispatch(setIsLoading(true));
      setTimeout(() => {
        dispatch(setIsLoading(false));
      }, 5000);
    }

    if (type === "test_stage_loader") {
      dispatch(setStageLoadingText("test"));
      setTimeout(() => {
        dispatch(setStageLoadingText(null));
      }, 5000);
    }

    if (type === "theme_log") {
      console.log("theme==>>", theme);
    }
    if (type === "sentry_error") {
      throw new Error("testing error");
    }
    if (type === "fetch_todos") {
      getTodos();
    }
    if (type === "fetch_translations") {
      refetchAllTranslations();
    }
  };

  const options = [
    {
      label: "Clear browser cache, cookies and reload",
      action: "browser_cache",
    },
    { label: "Reset all query Cache", action: "reset_cache" },
    {
      label: "Reset Integrations query Cache",
      action: "reset_integration_cache",
    },
    { label: "Test Loading", action: "test_loading" },
    { label: "Test Spinner", action: "test_spin" },
    { label: "Test Stage Loader", action: "test_stage_loader" },
    { label: "Open Maintenance Page", action: "open_maintenance_page" },
    { label: "Open Expired Plan Overlay", action: "open_expired_plan_overlay" },
    { label: "Open First Login Overlay", action: "open_first_login_overlay" },
    { label: "Open Updates Overlay", action: "open_updates_overlay" },
    { label: "Open Account Overlay", action: "open_account_overlay" },
    { label: `Language: ${profile?.locale}`, action: "language" },
    {
      label: `Beta: ${profile?.beta_enabled?.toString()}`,
      action: "beta_enabled",
    },
    {
      label: `Dev Mode : ${isDevMode?.toString()}`,
      action: "toggleDevMode",
    },
    {
      label: `Is owner: ${profile?.is_owner?.toString()}`,
      action: "user_role",
    },
    { label: "Reset first login", action: "first_login" },
    { label: "Reset header joyride", action: "dataset_header" },
    { label: "Reset reports joyride", action: "reports" },
    { label: "Report Sentry error", action: "sentry_error" },
    { label: "Themes log", action: "theme_log" },
    { label: "Fetch Todos", action: "fetch_todos" },
    { label: "Fetch Translations", action: "fetch_translations" },
  ];

  return (
    <PopupState
      variant="popper"
      id="demo-mutiple-checkbox"
      popupId="demo-popup-popover"
      sx={{
        zIndex: 1500,
      }}
    >
      {(popupState) => (
        <>
          <HeaderIconView
            {...bindTrigger(popupState)}
            tooltip=""
            icon={<TbLayoutDashboardFilled />}
            sx={{
              position: "absolute",
              top: "5.5rem",
              right: "1rem",
              zIndex: 1500,
            }}
          />
          <Popover
            {...bindPopover(popupState)}
            sx={{
              zIndex: 1500,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: 1.5,
                  boxShadow: Constant.OverlayBoxShadow,
                },
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "fit-content",
                minHeight: "3.125rem",
                width: "fit-content",
                minWidth: "18.75rem",
                position: "relative",
                p: "1rem",
              }}
            >
              {/* <SpecificContent /> */}
              {options.map((option) => (
                <Typography
                  key={option?.action}
                  variant="div"
                  sx={{ cursor: "pointer" }}
                  onClick={() => onClickFunction(option?.action, popupState)}
                >
                  {option?.label}
                </Typography>
              ))}
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

const SpecificContent = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    // if (iframe) {
    //   console.log("🚀 iframe:", iframe);
    //   const iframeDocument =
    //     iframe?.contentDocument || iframe?.contentWindow?.document;
    //   if (iframeDocument) {
    //     console.log("🚀 iframeDocument:", iframeDocument);
    //     const body = iframeDocument?.body;
    //     if (body) {
    //       console.log("🚀 body:", iframeDocument, body);
    //       // body.style.backgroundColor = "lightblue";
    //     }
    //   }
    // }
  }, []);

  const onClick = () => {
    var iframe = document.getElementById("iframe_");
    // console.log("🚀 / onClick / iframe:", iframe);
    var elmnt = iframe.contentWindow.document.getElementsByTagName("header")[0];
    // console.log("🚀 / onLoad / elmnt:", elmnt);
    elmnt.style.display = "none";
  };

  return (
    <Box
      sx={{
        "#SVGFooter": {
          display: "none",
        },
      }}
    >
      <button onClick={onClick}>Try it</button>
      <iframe
        ref={iframeRef}
        id="iframe_"
        title="Iframe"
        src="https://finban.io/documentation/ist-zahlen-offene-posten-und-planwerte/"
        style={{
          width: "45rem",
          marginBlock: "3rem",
          height: "40rem",
          // display: "none",
        }}
      ></iframe>
    </Box>
  );
};
