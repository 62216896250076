import {
  IconButton,
  Typography,
  Backdrop,
  Modal,
  Stack,
  Fade,
  Grow,
  Box,
} from "@mui/material";
import {
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
} from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { MdZoomOutMap } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import _ from "underscore";

import { cloneDeep, fetchConnectedBookedBalances } from "../../../Helper/data";
import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import Icon from "../../../components/Icon";
import Chart from "../Organizations/Chart";

const AccountsChart = ({ chart_data, setChartData, currency }) => {
  const theme = useTheme();
  const timeoutId = useRef(null);
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);

  const [disableRefresh, setDisableRefresh] = useState(false);

  const DS = useMemo(() => {
    return dataSource?.filter(
      (o1) => o1.currency === currency && o1.type === 12 && o1?.is_connected
    );
  }, [currency, dataSource]);

  const BookedParams = useMemo(() => {
    let obj = {
      apiParams: [],
      from_date: null,
      to_date: null,
    };
    if (DS?.length > 0) {
      const connectedDS = DS?.map((o1) => o1.uuid);
      obj.to_date = format(endOfMonth(new Date()), "yyyy-MM-dd");
      obj.from_date = format(
        startOfMonth(subMonths(new Date(), 11)),
        "yyyy-MM-dd"
      );
      if (connectedDS?.length > 0) {
        const datasetLinkedAccount = accounts?.filter(
          (o1) =>
            o1.currency === currency && connectedDS?.includes(o1?.data_source)
        );

        if (datasetLinkedAccount?.length > 0) {
          datasetLinkedAccount?.forEach((element) => {
            element?.balances.forEach((o1) => {
              if (o1.name === "booked") {
                obj.apiParams.push({
                  accountID: element?.uuid,
                  balanceID: o1?.uuid,
                });
              }
            });
          });
        }
      }
    }

    return obj;
  }, [DS, accounts, currency]);

  const { apiParams, from_date, to_date } = BookedParams;
  const _apiParams = useDeferredTimeout({ value: apiParams, timeoutMs: 2000 });

  const Booked_Balance = useQuery({
    queryKey: [
      "transactions",
      {
        apiType: "booked_balance",
        location: "start_page",
        type: "monthly",
        _apiParams,
      },
    ],
    queryFn: ({ signal }) => {
      const result = fetchConnectedBookedBalances({
        array: cloneDeep(_apiParams),
        params: {
          config: {
            signal,
          },
          from_date,
          to_date,
        },
      });
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled:
      !isFirstLoginOverlayOpen &&
      !!DS &&
      DS?.length > 0 &&
      !!from_date &&
      _apiParams?.length > 0,
    priority: 5,
    staleTime: 30000,
  });

  //data
  const data = useMemo(() => {
    let array = [];
    if (Booked_Balance?.data?.data && DS?.length > 0) {
      const Booked_BalanceByMonth = _.groupBy(
        Booked_Balance?.data?.data,
        ({ month }) => month
      );
      const DateRange = eachMonthOfInterval({
        start: new Date(from_date),
        end: new Date(to_date),
      });
      let latest_balance = {};

      DateRange?.forEach((date) => {
        const formattedDate = format(date, "yyyy-MM");
        const monthObj = Booked_BalanceByMonth?.[formattedDate]?.[0] || {};
        let obj = monthObj;
        apiParams?.forEach(({ accountID }) => {
          if (!obj?.hasOwnProperty(accountID)) {
            obj[accountID] = latest_balance?.[accountID] || 0;
          }
          latest_balance[accountID] = monthObj?.[accountID] || 0;
        });
        obj["due_date"] = formattedDate;
        array.push(obj);
      });
    }

    return array?.sort((a, b) => new Date(a?.due_date) - new Date(b?.due_date));
  }, [Booked_Balance?.data?.data, DS?.length, apiParams, from_date, to_date]);

  //lifecycle
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current); // Clear the timeout if the component unmounts
    };
  }, []);

  useEffect(() => {
    setChartData({ isFetching: Booked_Balance?.isFetching, data });
  }, [Booked_Balance?.isFetching, data, setChartData]);

  if (!apiParams || apiParams?.length === 0) {
    return null;
  }

  // functions
  const onClickRefresh = () => {
    setDisableRefresh(true);

    timeoutId.current = setTimeout(() => {
      setDisableRefresh(false);
    }, 60000);
    Booked_Balance.refetch();
  };

  return (
    <Stack
      sx={{
        display: "flex",
        height: "15rem",
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        p: "1.5rem",
        pb: chart_data?.isFetching ? "1.5rem" : "1rem",
        mb: "1.5rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: theme.boxShadow,
        width: "100%",
      }}
    >
      <Chart
        dataset={{ uuid: "overview" }}
        chart_data={chart_data}
        Xaxis={{
          hide: true,
        }}
        Yaxis={{
          hide: true,
        }}
        margin={{
          top: 20,
          bottom: 20,
        }}
        tick={{
          fontSize: "0.6rem",
          fontWeight: 600,
        }}
      />
      {chart_data?.data?.length > 0 && !chart_data?.isFetching ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          sx={{ width: "100%", gap: "0.25rem", mb: "-4px" }}
        >
          <IconButton
            disabled={disableRefresh}
            onClick={onClickRefresh}
            sx={{ background: "transparent", mr: "0.25rem", p: "3px" }}
          >
            <Icon
              tooltip={"Refresh"}
              icon={<IoMdRefresh />}
              color={theme.palette.color.slate[disableRefresh ? 300 : 600]}
              fontSize={"1.7rem"}
            />
          </IconButton>
          <ZoomChart chart_data={chart_data} />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default AccountsChart;

const ZoomChart = ({ chart_data }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton
        onClick={handleOpen}
        sx={{ background: "transparent", p: "3px", mr: "-3px" }}
      >
        <Icon
          tooltip={"Zoom"}
          icon={<MdZoomOutMap />}
          color={theme.palette.color.slate[600]}
          fontSize={"1.4rem"}
        />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{ zIndex: 1303 }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: theme.borderRadius.main,
              backgroundColor: theme.palette.color.white,
              p: "1rem",
              width: "80%",
              height: "65%",
            }}
          >
            <Chart
              dataset={{ uuid: "overview" }}
              chart_data={chart_data}
              margin={{
                top: 30,
                left: 20,
                right: 30,
              }}
              tick={{
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
